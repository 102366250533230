import { UserAgentApplication } from 'msal';
import { CLIENT_ID, TENANT_ID, TOKEN_SCOPE } from 'config/msal';

const locationOrigin = window.location ? window.location.origin : null;

const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    redirectUri: locationOrigin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};
const ACCESS_TOKEN_NAME = 'accessToken';
class MsalAuth {
  constructor() {
    this.msal = new UserAgentApplication(msalConfig);
    this.setEvents();
    this.logout = this.logout.bind(this);
  }

  setEvents() {
    this.msal.handleRedirectCallback(
      (error, response) => {
        console.info('success', error, JSON.stringify(response));
      },
      (error, response) => {
        console.info('error', error, response);
      }
    );
  }

  getAccount = async () => {
    const accountData = await this.msal.getAccount();
    return accountData;
  };

  getTokenSilent = () => this.msal.acquireTokenSilent({ scopes: [TOKEN_SCOPE] });

  redirectToLogin(extra = {}) {
    this.msal.loginRedirect({ ...extra, scopes: [TOKEN_SCOPE] });
  }

  acquireTokenRedirect = () => this.msal.acquireTokenRedirect({ scopes: [TOKEN_SCOPE] });

  isUserLoginError = (errorMessage) => Boolean(errorMessage && errorMessage.indexOf('user_login_error') > -1);

  requiresInteraction = (errorMessage) => {
    if (!errorMessage || !errorMessage.length) {
      return false;
    }

    return (
      errorMessage.indexOf('consent_required') > -1
      || errorMessage.indexOf('interaction_required') > -1
      || errorMessage.indexOf('login_required') > -1
    );
  };

  logout() {
    this.msal.logout();
  }
}

export { ACCESS_TOKEN_NAME };
export default MsalAuth;
