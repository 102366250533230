import API from 'API';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifier';
import * as typeNotifier from 'shared/typeNotifications';
import * as informationMessages from 'constants/infoMessages';
import { getDayOfTheWeek } from 'shared/dateFormat';

export const START_LOAD_FORECAST_LIST = 'START_LOAD_FORECAST_LIST';
export const SUCCESS_LOAD_FORECAST_LIST = 'SUCCESS_LOAD_FORECAST_LIST';
export const START_LOAD_FORECAST_METRICS = 'START_LOAD_FORECAST_METRICS';
export const SUCCESS_LOAD_FORECAST_METRICS = 'SUCCESS_LOAD_FORECAST_METRICS';
export const DATE_CHANGE_FORECAST = 'DATE_CHANGE_FORECAST';
export const CHANGE_CHECKBOX = 'CHANGE_CHECKBOX';
export const ERROR_LOAD_FORECAST = 'ERROR_LOAD_FORECAST';
export const CHANGE_HORIZON_FORECAST = 'CHANGE_HORIZON_FORECAST';

export const loadForecastList = (type, t) => (dispatch) => {
  dispatch({ type: START_LOAD_FORECAST_LIST });
  API.getForecastList(type)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_FORECAST_LIST,
        data,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_LOAD_FORECAST,
      });
      dispatch(enqueueSnackbarAction({
        message: t(`infoMessages:${informationMessages.DATA_LOADING_ERROR}`),
        options: {
          variant: typeNotifier.ERROR,
        },
      }));
      console.error('LOAD_FORECAST_LIST');
    });
};
export const loadForecastMetrics = ({
  typeStation, plantId, providerIds, date, horizon, t
}) => (dispatch) => {
  dispatch({ type: START_LOAD_FORECAST_METRICS });
  if (getDayOfTheWeek(date.from) !== 1 || getDayOfTheWeek(date.to) !== 0) {
    dispatch(enqueueSnackbarAction({
      message: t(`infoMessages:${informationMessages.SELECTED_PERIOD_NOT_MULTIPLE_CALENDAR_WEEK}`),
      options: {
        variant: typeNotifier.WARNING,
        autoHideDuration: 60000
      },
    }));
  }
  API.getForecastMetrics({
    typeStation, plantId, providerIds, date, horizon
  })
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_FORECAST_METRICS,
        data,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_LOAD_FORECAST,
      });
      dispatch(enqueueSnackbarAction({
        message: t(`infoMessages:${informationMessages.DATA_LOADING_ERROR}`),
        options: {
          variant: typeNotifier.ERROR,
        },
      }));
      console.error('LOAD_FORECAST_METRICS');
    });
};
