import axios from 'axios';

import { ACCESS_TOKEN_NAME } from 'utils/MsalAuth';
import { msal } from 'containers/RootAuth/RootAuth';
import getStorageLanguage from 'utils/getLang';

const axiosApiInstance = axios.create();

const setUserToken = ({ accessToken = {} }) => {
  localStorage.setItem(ACCESS_TOKEN_NAME, accessToken);
  return accessToken;
};

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_NAME)
      ? localStorage.getItem(ACCESS_TOKEN_NAME)
      : null;
    const auth = {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    };

    const { headers } = config;
    const newConfig = {
      ...config,
      headers: {
        ...headers,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        lang: getStorageLanguage(),
        ...auth,
      }
    };
    return newConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response ? error.response.status : null;
    const originalRequest = error.config;
    // eslint-disable-next-line no-underscore-dangle
    if (status === 401 && !originalRequest._retry) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;
      return msal
        .getTokenSilent()
        .catch(({ errorCode = null }) => {
          console.error('getAccessToken Error');
          if (msal.requiresInteraction(errorCode)) {
            msal.acquireTokenRedirect();
            return;
          }
          if (msal.isUserLoginError(errorCode)) {
            msal.redirectToLogin();
          }
        })
        .then(setUserToken)
        .then((accessToken) => {
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          return axiosApiInstance(originalRequest);
        });
    }
    return Promise.reject(error);
  }
);

export default axiosApiInstance;
