import {
  SET_HANDLE_LOGOUT,
  SET_USER_DATA, CHANGE_LANGUAGE,
  SUCCESS_LOAD_PERMISSION
} from 'store/actions/account';
import getStorageLanguage from 'utils/getLang';

const initialState = {
  userName: '',
  name: '',
  handleLogout: null,
  language: getStorageLanguage(),
  roleId: null,
  plantIds: [],
  isLoadedPermission: false,
};

const account = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_USER_DATA: {
      const { userName, name } = data;
      return {
        ...state,
        userName,
        name,
      };
    }
    case SET_HANDLE_LOGOUT:
      return {
        ...state,
        handleLogout: data,
      };
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: data,
      };
    case SUCCESS_LOAD_PERMISSION: {
      const { plantIds, roleId } = data;
      return {
        ...state,
        plantIds,
        roleId,
        isLoadedPermission: true,
      }; }
    default:
      return state;
  }
};

export default account;
