export const ENUM_HEAD = {
  ID_KEY: 1,
  ID_VALUE: 'peopleId',
  EMAIL_KEY: 2,
  EMAIL_VALUE: 'email',
  NAME_KEY: 7,
  NAME_VALUE: 'user',
  ROLE_KEY: 4,
  ROLE_VALUE: 'role',
  LOCATION_KEY: 5,
  LOCATION_VALUE: 'location',
};

export const headCells = [
  {
    key: ENUM_HEAD.ID_KEY,
    value: ENUM_HEAD.ID_VALUE
  },
  {
    key: ENUM_HEAD.EMAIL_KEY,
    value: ENUM_HEAD.EMAIL_VALUE
  },
  {
    key: ENUM_HEAD.NAME_KEY,
    value: ENUM_HEAD.NAME_VALUE
  },
  {
    key: ENUM_HEAD.ROLE_KEY,
    value: ENUM_HEAD.ROLE_VALUE
  },
  {
    key: ENUM_HEAD.LOCATION_KEY,
    value: ENUM_HEAD.LOCATION_VALUE
  },
];

export const MULTI_SELECT_ITEM_SIZE = 52;

export const OPTIONS_KEY = 'id';
export const OPTIONS_VALUE = 'name';

export const CREATE_USER_TYPE = {
  CREATE: 0,
  CREATED: 1,
  DUPLICATE: 2,
  NOT_FOUND: 3,
};
