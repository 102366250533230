import {
  START_LOAD_GENERAL_STATISTIC,
  SUCCESS_LOAD_GENERAL_STATISTIC,
  LOAD_DYNAMIC_DATA_GENERAL_STATISTIC,
  ERROR_LOAD_GENERAL_STATISTIC,
  DATE_CHANGE_GENERAL_STATISTIC
} from 'store/actions/generalStatistic';
import { dateFormatISO } from 'shared/dateFormat';

import { isEmpty } from 'lodash';
import {
  endOfToday,
  startOfYear,
} from 'date-fns';

const currentDate = new Date();
const defaultDate = {
  from: dateFormatISO(startOfYear(currentDate)),
  to: dateFormatISO(endOfToday(currentDate))
};

const initialState = {
  productionBudget: {
    nameChart: '',
    y1Name: '',
    y2Name: '',
    y1AvgName: '',
    y2AvgName: '',
    y1Avg: '',
    y2Avg: '',
    data: []
  },
  cumulativeProductionBudget: {
    nameChart: '',
    y1Name: '',
    y2Name: '',
    y1AvgName: '',
    y2AvgName: '',
    y1Avg: '',
    y2Avg: '',
    data: []
  },
  averageDailyInsolation: {
    nameChart: '',
    y1Name: '',
    y2Name: '',
    y1AvgName: '',
    y2AvgName: '',
    y1Avg: '',
    y2Avg: '',
    data: []
  },
  windResource: {
    nameChart: '',
    y1Name: '',
    y2Name: '',
    y1AvgName: '',
    y2AvgName: '',
    y1Avg: '',
    y2Avg: '',
    data: []
  },
  energyAvailabilitySpp: {
    nameChart: '',
    y1Name: '',
    y2Name: '',
    y1AvgName: '',
    y2AvgName: '',
    y1Avg: '',
    y2Avg: '',
    data: []
  },
  energyAvailabilityWpp: {
    nameChart: '',
    y1Name: '',
    y2Name: '',
    y1AvgName: '',
    y2AvgName: '',
    y1Avg: '',
    y2Avg: '',
    data: []
  },
  date: defaultDate,
  isLoading: false,
  isLoaded: false,
  isError: false,
};
const generalStatistic = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD_GENERAL_STATISTIC: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS_LOAD_GENERAL_STATISTIC: {
      const { data } = action;
      return {
        ...state,
        ...data,
        isLoading: false,
        isLoaded: !isEmpty(data),
      };
    }
    case LOAD_DYNAMIC_DATA_GENERAL_STATISTIC: {
      const { data } = action;
      return {
        ...state,
        ...data,
        isLoaded: !isEmpty(data)
      };
    }
    case DATE_CHANGE_GENERAL_STATISTIC: {
      return {
        ...state,
        date: { ...state.date, ...action.date }
      };
    }
    case ERROR_LOAD_GENERAL_STATISTIC: {
      return {
        ...state,
        isError: true,
      };
    }
    default:
      return state;
  }
};

export default generalStatistic;
