import { isEmpty } from 'lodash';

import {
  subWeeks,
  endOfISOWeek,
  startOfISOWeek,
} from 'date-fns';
import { dateFormatISO } from 'shared/dateFormat';

import {
  START_LOAD_UNBALANCE_CHARTS,
  SUCCESS_LOAD_UNBALANCE_CHARTS,
  ERROR_LOAD_UNBALANCE_CHARTS,
  DATE_PRESET_CHANGE_UNBALANCE_CHARTS,
  DRP_CHANGE_UNBALANCE_CHARTS,
} from 'store/actions/unbalance';

const currentDate = new Date();
const defaultDate = {
  from: dateFormatISO(new Date(subWeeks(startOfISOWeek(currentDate), 1))),
  to: dateFormatISO(new Date(subWeeks(endOfISOWeek(currentDate), 1)))
};

const initialState = {
  unbalanceProduction: {
    nameChart: '',
    y1Name: '',
    y2Name: '',
    xDateType: 0,
    data: []
  },
  date: defaultDate,
  isChangeDRP: false,
  isLoading: false,
  isLoaded: false,
  isError: false,
};
const unbalance = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD_UNBALANCE_CHARTS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS_LOAD_UNBALANCE_CHARTS: {
      const { data } = action;
      return {
        ...state,
        ...data,
        isLoading: false,
        isChangeDRP: false,
        isLoaded: !isEmpty(data),
      };
    }
    case ERROR_LOAD_UNBALANCE_CHARTS: {
      return {
        ...state,
        isError: true,
      };
    }
    case DATE_PRESET_CHANGE_UNBALANCE_CHARTS: {
      return {
        ...state,
        isChangeDRP: false,
        date: { ...state.date, ...action.date }
      };
    }
    case DRP_CHANGE_UNBALANCE_CHARTS: {
      return {
        ...state,
        isChangeDRP: true,
        date: { ...state.date, ...action.date }
      };
    }
    default:
      return state;
  }
};

export default unbalance;
