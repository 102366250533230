import {
  START_LOAD_STATIONS_FOR_MAP,
  SUCCESS_LOAD_STATIONS_FOR_MAP,
} from 'store/actions/map';

const initialState = {
  spp: [],
  wpp: [],
  isLoading: false,
  isLoaded: false,
};
const map = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD_STATIONS_FOR_MAP: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS_LOAD_STATIONS_FOR_MAP: {
      const {
        data: { spp, wpp },
      } = action;
      return {
        ...state,
        spp: [...state.spp, ...spp],
        wpp: [...state.wpp, ...wpp],
        isLoading: false,
        isLoaded: true,
      };
    }
    default:
      return state;
  }
};

export default map;
