import API from 'API';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifier';
import * as typeNotifier from 'shared/typeNotifications';
import * as informationMessages from 'constants/infoMessages';

export const START_LOAD_BUSINESS_PLAN = 'START_LOAD_BUSINESS_PLAN';
export const SUCCESS_LOAD_BUSINESS_PLAN = 'SUCCESS_LOAD_BUSINESS_PLAN';
export const ERROR_LOAD_BUSINESS_PLAN = 'ERROR_LOAD_BUSINESS_PLAN';

export const loadDataBusinessPlan = (t) => (dispatch) => {
  dispatch({ type: START_LOAD_BUSINESS_PLAN });
  API.getDataBusinessPlan()
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_BUSINESS_PLAN,
        data,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_LOAD_BUSINESS_PLAN,
      });
      dispatch(enqueueSnackbarAction({
        message: t(`infoMessages:${informationMessages.DATA_LOADING_ERROR}`),
        options: {
          variant: typeNotifier.ERROR,
        },
      }));
      console.error('LOAD_ALL_CHARTS');
    });
};
