import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Toolbar, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { useTranslation } from 'react-i18next';
import { toggleDrawer } from 'store/actions/sidebar';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import { CHANGE_LANGUAGE } from 'store/actions/account';
import SelectControl from 'components/SelectControl';
import { ReactComponent as LogoSvg } from 'assets/logo.svg';
import useStyles from './styles';

const languageList = [
  {
    value: 'uk',
    text: 'УКР'
  },
  {
    value: 'en',
    text: 'ENG'
  },
  {
    value: 'ru',
    text: 'РУС'
  }
];

function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { userName, handleLogout, language: defaultLanguage } = useSelector(({ account }) => account);
  const { open } = useSelector(({ sidebar }) => sidebar);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(defaultLanguage);
  }, [defaultLanguage, i18n]);

  const handleChangeLanguage = useCallback(
    ({ target: { value } }) => {
      localStorage.setItem('language', JSON.stringify(value));
      dispatch({
        type: CHANGE_LANGUAGE,
        data: value,
      });
    },
    [dispatch],
  );

  const openDrawer = () => {
    dispatch(toggleDrawer(true));
  };
  const closeDrawer = () => {
    dispatch(toggleDrawer(false));
  };

  return (
    <Toolbar className={classes.root}>
      <div className={classes.toolbar}>
        {open ? (
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        ) : (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={openDrawer}
          >
            <MenuIcon />
          </IconButton>
        )}
      </div>
      <Link className={classes.logoContainer} to="/">
        <LogoSvg />
        <Typography className="title" variant="subtitle1" noWrap>
          ANL-001
        </Typography>
      </Link>
      <Grid
        className={classes.accountBlock}
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <SelectControl
          items={languageList}
          selectedItem={defaultLanguage}
          variant="standard"
          onHandleChange={handleChangeLanguage}
        />
        <div className={classes.user}>
          {userName}
        </div>
        <AccountCircleOutlinedIcon className="iconAccount" />
        <IconButton
          className="logout"
          aria-label="logout"
          onClick={() => dispatch(handleLogout())}
        >
          <PowerSettingsNewIcon />
        </IconButton>
      </Grid>
    </Toolbar>
  );
}

export default Header;
