export const BUSINESS_PLAN_TABLE = {
  id: 'BusinessPlan',
  description: 'BusinessPlan',
  type: 'array',
  items: {
    $ref: '#/$defs/BusinessPlanArray'
  },
  $defs: {
    BusinessPlanArray: {
      type: 'object',
      properties: {
        id: {
          type: 'integer',
          format: 'int32'
        },
        year: {
          type: 'integer',
          format: 'int32'
        },
        date: {
          type: 'string',
          nullable: true
        },
        author: {
          type: 'string',
          nullable: true
        }
      },
    },
  }
};

export const BUSINESS_PLAN_DETAILS = {
  id: 'BusinessPlanDetails',
  description: 'BusinessPlan/details/id',
  type: 'object',
  properties: {
    year: {
      type: 'integer',
      format: 'int32'
    },
    body: {
      type: 'array',
      items: {
        $ref: '#/$defs/BusinessPlanRow'
      },
      nullable: true
    }
  },
  additionalProperties: false,
  $defs: {
    BusinessPlanRow: {
      type: 'object',
      properties: {
        id: {
          type: 'integer',
          format: 'int32'
        },
        name: {
          type: 'string',
          nullable: true
        },
        rowData: {
          type: 'array',
          items: {
            $ref: '#/$defs/BusinessPlanCell'
          },
          nullable: true
        }
      },
      additionalProperties: false
    },
    BusinessPlanCell: {
      type: 'object',
      properties: {
        data: {
          type: 'string',
          format: 'double',
          nullable: true
        },
        isValid: {
          type: 'boolean',
          nullable: true
        }
      },
      additionalProperties: false
    },
  }
};

export const PLANT_ALL = {
  id: 'PlantAll',
  description: 'Plant/All',
  method: 'GET',
  url: '.../Plant/All',
  type: 'object',
  properties: {
    spp: {
      type: 'array',
      items: {
        $ref: '#/$defs/sppPlant'
      },
    },
    wpp: {
      type: 'array',
      items: {
        $ref: '#/$defs/wppPlant'
      },
      nullable: true
    },
  },
  $defs: {
    sppPlant: {
      required: [
        'name',
        'latitude',
        'longitude',
        'asset'
      ],
      type: 'object',
      properties: {
        id: {
          type: 'integer',
          format: 'int32'
        },
        name: {
          type: 'string',
          nullable: true
        },
        fullName: {
          type: 'string',
          nullable: true
        },
        latitude: {
          type: 'string',
          nullable: true
        },
        longitude: {
          type: 'string',
          nullable: true
        },
        asset: {
          type: 'string',
          nullable: true
        },
        assetFullName: {
          type: 'string',
          nullable: true
        },
        nominalPowerKw: {
          type: 'integer',
          format: 'int64'
        }
      }
    },
    wppPlant: {
      type: 'object',
      properties: {
        id: {
          type: 'integer',
          format: 'int32'
        },
        name: {
          type: 'string',
          nullable: true
        },
        fullName: {
          type: 'string',
          nullable: true
        },
        latitude: {
          type: 'string',
          nullable: true
        },
        longitude: {
          type: 'string',
          nullable: true
        },
        asset: {
          type: 'string',
          nullable: true
        },
        assetFullName: {
          type: 'string',
          nullable: true
        },
        nominalPowerKw: {
          type: 'integer',
          format: 'int64'
        }
      }
    }
  }

};

export const RESTRICTION_LOG = {
  id: 'RestrictionLog?param',
  description: 'RestrictionLog',
  method: 'GET',
  url: '.../RestrictionsJournal/?limit={}&page={}&plantIds={}&requestStatus={}&from={}&to={}',
  type: 'object',
  properties: {
    logs: {
      type: 'array',
      items: {
        $ref: '#/$defs/RestrictionLogEntryK'
      },
      nullable: true
    },
    count: {
      type: 'integer',
      format: 'int32'
    }
  },
  $defs: {
    RestrictionLogEntryK: {
      type: 'object',
      properties: {
        id: {
          type: 'integer',
          format: 'int64',
          nullable: true
        },
        requestStatus: {
          $ref: '#/$defs/LogRequestStatuses'
        },
        plantId: {
          type: 'integer',
          format: 'int32',
          nullable: true
        },
        plantName: {
          type: 'string',
          nullable: true
        },
        from: {
          type: 'string',
          format: 'date-time',
          nullable: true
        },
        to: {
          type: 'string',
          format: 'date-time',
          nullable: true
        },
        powerCommandRestriction: {
          type: 'string',
          nullable: true
        },
        powerBefore: {
          type: 'number',
          format: 'double',
          nullable: true
        },
        powerAfter: {
          type: 'number',
          format: 'double',
          nullable: true
        },
        lossesSCADA: {
          type: 'number',
          format: 'double',
          nullable: true
        },
        lossesCalculatedOrEtalon: {
          type: 'number',
          format: 'double',
          nullable: true
        }
      },
      additionalProperties: false
    },
    LogRequestStatuses: {
      enum: [
        1,
        2,
        3
      ],
      type: 'integer',
      format: 'int32'
    },
  },
  additionalProperties: false
};
