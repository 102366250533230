import React, { Suspense, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as routes from 'shared/routes';
import { PrivateRoute } from 'containers/RoleModel';
import Loader from 'pages/PageLoader';
import routeList from './routeConfig';

const PageNotFound = lazy(() => import('pages/404'));

const Routes = () => {
  const { spp, wpp } = useSelector(({ stations }) => stations);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Redirect exact from="/" to={routes.DASHBOARD} />
          {routeList.map((route) => (
            route.permission
                && (
                  <PrivateRoute
                    key={route.path}
                    plants={{ spp, wpp }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                    {...route}
                  />
                )))}
          <Route from="/" render={() => <PageNotFound />} />
        </Switch>
      </Suspense>
    </>
  );
};

export default Routes;
