import API from 'API';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifier';
import * as typeNotifier from 'shared/typeNotifications';
import * as informationMessages from 'constants/infoMessages';

export const START_LOAD_OJ = 'START_LOAD_OJ';
export const SUCCESS_LOAD_OJ = 'SUCCESS_LOAD_OJ';
export const ERROR_LOAD_OJ = 'ERROR_LOAD_OJ';

export const DATE_CHANGE_FILTER_OJ = 'DATE_CHANGE_FILTER_OJ';
export const MULTI_SELECT_CHANGE_FILTER_OJ = 'MULTI_SELECT_CHANGE_FILTER_OJ';
export const CHECKBOX_CHANGE_FILTER_OJ = 'CHECKBOX_CHANGE_FILTER_OJ';
export const APPLY_FILTER_OJ = 'APPLY_FILTER_OJ';
export const CLEAR_FILTER_OJ = 'CLEAR_FILTER_OJ';
export const SET_PAGINATION_OJ = 'SET_PAGINATION_OJ';
export const SET_SORTING_OJ = 'SET_SORTING_OJ';

export const CLEAR_OJ_ITEM = 'CLEAR_OJ_ITEM';

export const START_LOAD_OJ_ITEM = 'START_LOAD_OJ_ITEM';
export const SUCCESS_LOAD_OJ_ITEM = 'SUCCESS_LOAD_OJ_ITEM';
export const ERROR_LOAD_OJ_ITEM = 'ERROR_LOAD_OJ_ITEM';

export const START_CREATE_OJ_ITEM = 'START_CREATE_OJ_ITEM';
export const SUCCESS_CREATE_OJ_ITEM = 'SUCCESS_CREATE_OJ_ITEM';
export const ERROR_CREATE_OJ_ITEM = 'ERROR_CREATE_OJ_ITEM';

export const START_UPDATE_OJ_ITEM = 'START_UPDATE_OJ_ITEM';
export const SUCCESS_UPDATE_OJ_ITEM = 'SUCCESS_UPDATE_OJ_ITEM';
export const ERROR_UPDATE_OJ_ITEM = 'ERROR_UPDATE_OJ_ITEM';

export const START_DELETE_OJ_ITEM = 'START_DELETE_OJ_ITEM';
export const SUCCESS_DELETE_OJ_ITEM = 'SUCCESS_DELETE_OJ_ITEM';
export const ERROR_DELETE_OJ_ITEM = 'ERROR_DELETE_OJ_ITEM';

export const START_LOAD_FILTER_OPTIONS_OJ = 'START_LOAD_FILTER_OPTIONS_OJ';
export const SUCCESS_LOAD_FILTER_OPTIONS_OJ = 'SUCCESS_LOAD_FILTER_OPTIONS_OJ';

export const START_LOAD_PLANT_OJ = 'START_LOAD_PLANT_OJ';
export const SUCCESS_LOAD_PLANT_OJ = 'SUCCESS_LOAD_PLANT_OJ';
export const SELECT_CHANGE_PLANT_OJ = 'SELECT_CHANGE_PLANT_OJ';

export const getOperationalJournal = (t, dto) => (dispatch) => {
  dispatch({ type: START_LOAD_OJ });
  API.getOperationalJournalTable(dto)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_OJ,
        data,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_LOAD_OJ,
      });
      dispatch(enqueueSnackbarAction({
        message: t(`infoMessages:${informationMessages.DATA_LOADING_ERROR}`),
        options: {
          variant: typeNotifier.ERROR,
        },
      }));
      console.error('OJ_TABLE');
    });
};

export const deleteOJItem = (id, message) => (dispatch) => {
  dispatch({ type: START_DELETE_OJ_ITEM });
  API.deleteOperationalJournalItem(id)
    .then(() => {
      dispatch({
        type: SUCCESS_DELETE_OJ_ITEM
      });
      dispatch(enqueueSnackbarAction({
        message,
        options: {
          variant: typeNotifier.SUCCESS,
        },
      }));
    })
    .catch(() => {
      dispatch({
        type: ERROR_DELETE_OJ_ITEM,
      });
    });
};

export const getOJPlants = () => (dispatch) => {
  dispatch({ type: START_LOAD_PLANT_OJ });
  API.getOJPlants()
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_PLANT_OJ,
        stationId: data,
      });
    })
    .catch(() => {
      console.error('ERROR_LOAD_PLANT_OJ');
    });
};

export const getOJItemById = (id) => (dispatch) => {
  dispatch({ type: START_LOAD_OJ_ITEM });
  API.getOJItemById(id)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_OJ_ITEM,
        data,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_LOAD_OJ_ITEM,
      });
    });
};

export const getOJFilterOptions = (t, id) => (dispatch) => {
  API.getOJFilterOptions(id)
    .then((data) => {
      dispatch({
        type: SUCCESS_LOAD_FILTER_OPTIONS_OJ,
        equipmentOptions: data[0].status === 'fulfilled' && data[0].value.data,
        eventCategoryOptions: data[1].status === 'fulfilled' && data[1].value.data,
        eventOptions: data[2].status === 'fulfilled' && data[2].value.data,
      });
    })
    .catch(() => {
      dispatch(enqueueSnackbarAction({
        message: t(`infoMessages:${informationMessages.DATA_LOADING_ERROR}`),
        options: {
          variant: typeNotifier.ERROR,
        },
      }));
      console.error('ERROR_LOAD_FILTER_OPTIONS_OJ');
    });
};

export const createOJItem = (param) => (dispatch) => {
  dispatch({ type: START_CREATE_OJ_ITEM });
  API.createOJItem(param)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_CREATE_OJ_ITEM,
        data,
      });
      dispatch(enqueueSnackbarAction({
        message: `Запись ${data}  успешно создана`,
        options: {
          variant: typeNotifier.SUCCESS,
        },
      }));
    })
    .catch(() => {
      dispatch({
        type: ERROR_CREATE_OJ_ITEM,
      });
    });
};

export const updateOJItem = (param, t, isRestore) => (dispatch) => {
  dispatch({ type: START_UPDATE_OJ_ITEM });
  API.updateOJItem(param)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_UPDATE_OJ_ITEM,
        data,
      });
      dispatch(enqueueSnackbarAction({
        message: isRestore
          ? `Запись ${data} успешно восстановлена`
          : t(`infoMessages:${informationMessages.DATA_UPDATE_SUCCESS}`),
        options: {
          variant: typeNotifier.SUCCESS,
        },
      }));
    })
    .catch(() => {
      dispatch({
        type: ERROR_UPDATE_OJ_ITEM,
      });
      dispatch(enqueueSnackbarAction({
        message: t(`infoMessages:${informationMessages.DATA_UPDATE_ERROR}`),
        options: {
          variant: typeNotifier.ERROR,
        },
      }));
    });
};
