import {
  START_LOAD_STATIONS,
  SUCCESS_LOAD_STATIONS,
} from 'store/actions/stations';

const initialState = {
  spp: [],
  wpp: [],
  isLoading: false,
};
const stations = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD_STATIONS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS_LOAD_STATIONS: {
      const {
        data: { spp, wpp },
      } = action;
      return {
        ...state,
        spp: [...state.spp, ...spp],
        wpp: [...state.wpp, ...wpp],
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default stations;
