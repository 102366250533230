import API from 'API';
import { saveAs } from 'file-saver';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifier';
import * as typeNotifier from 'shared/typeNotifications';
import * as informationMessages from 'constants/infoMessages';

export const START_LOAD_REPORT_FILE = 'START_LOAD_REPORT_FILE';
export const SUCCESS_LOAD_REPORT_FILE = 'SUCCESS_LOAD_REPORT_FILE';
export const ERROR_LOAD_REPORT_FILE = 'ERROR_LOAD_REPORT_FILE';
export const SUCCESS_LOAD_UPDATE_FILE = 'SUCCESS_LOAD_UPDATE_FILE';

export const uploadFile = (file, t) => (dispatch) => {
  dispatch({ type: START_LOAD_REPORT_FILE });
  API.uploadFile(file)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_REPORT_FILE,
        data,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_LOAD_REPORT_FILE,
      });
      dispatch(enqueueSnackbarAction({
        message: t(`infoMessages:${informationMessages.DATA_LOADING_ERROR}`),
        options: {
          variant: typeNotifier.ERROR,
        },
      }));
      console.error('LOAD_ALL_REPORT_FILE');
    });
};

export const downloadFile = (id, fileName, typeReport, t) => (dispatch) => {
  API.downloadFile(id, typeReport)
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.xlsx`);
    })
    .catch(() => {
      dispatch(enqueueSnackbarAction({
        message: t(`infoMessages:${informationMessages.DATA_LOADING_ERROR_FILE}`),
        options: {
          variant: typeNotifier.ERROR,
        },
      }));
      console.error('LOAD_ALL_REPORT_FILE');
    });
};

export const updateFile = (id) => (dispatch) => {
  API.updateFile(id)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_UPDATE_FILE,
        data,
      });
    })
    .catch(() => {
      dispatch(enqueueSnackbarAction({
        message: 'Ошибка обновления файла',
        options: {
          variant: typeNotifier.ERROR,
        },
      }));
      console.error('LOAD_UPDATE_FILE BP');
    });
};
