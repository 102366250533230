import {
  START_LOAD_BUSINESS_PLAN_DETAILS,
  SUCCESS_LOAD_BUSINESS_PLAN_DETAILS,
  ERROR_LOAD_BUSINESS_PLAN_DETAILS,
} from 'store/actions/businessPlanDetails';

import { isEmpty } from 'lodash';

const initialState = {
  businessPlanData: [],
  isLoading: false,
  isLoaded: false,
  isError: false,
};
const businessPlanDetails = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD_BUSINESS_PLAN_DETAILS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS_LOAD_BUSINESS_PLAN_DETAILS: {
      const { data } = action;
      return {
        ...state,
        businessPlanData: data,
        isLoading: false,
        isLoaded: !isEmpty(data),
      };
    }
    case ERROR_LOAD_BUSINESS_PLAN_DETAILS: {
      return {
        ...state,
        isError: true,
      };
    }
    default:
      return state;
  }
};

export default businessPlanDetails;
