import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none',
  },
  drawer: {
    width: theme.drawerWidth,
    bottom: 0,
    whiteSpace: 'nowrap',
    zIndex: theme.zIndex.drawer,
    '& .MuiPaper-root': {
      backgroundColor: theme.primarySb,
      paddingTop: 64,
    },
    '& .MuiList-root': {
      padding: 0,
    },
    '& .MuiListItem-button, .MuiButtonBase-root': {
      color: theme.palette.white,
      transition: 'color 0.4s',
      '&:hover': {
        color: theme.palette.sidebar.active,
      },
    },
    '& .MuiButtonBase-root': {
      padding: 0,
      minHeight: theme.sidebarCloseWidth,
      '&:before': {
        content: '""',
        position: 'absolute',
        height: '100%',
        width: '4px',
        top: '0',
        left: '0',
      },
      '&.Mui-selected': {
        background: theme.primarySbSelected,
        color: theme.palette.sidebar.active,
        '&:before': {
          background: theme.palette.sidebar.active,
        },
      },
    },
    '& .MuiListItemIcon-root': {
      minWidth: theme.sidebarCloseWidth,
      justifyContent: 'center',
      color: 'inherit',
    },
  },
  drawerOpen: {
    width: theme.drawerWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.sidebarCloseWidth,
  },

}));

export default useStyles;
