export const NO_DATA = 'noData';
export const SELECTED_PERIOD_NOT_MULTIPLE_CALENDAR_WEEK = 'selectedPeriodNotMultipleCalendarWeek';
export const LOADING_PLEASE_WAIT = 'LoadingPleaseWait';
export const NOT_ACCESS = 'notAccess';
export const CONNECT_SUPPORT = 'connectSupport';
export const DATA_LOADING_ERROR = 'dataLoadingError';
export const DATA_LOADING_ERROR_FILE = 'errorLoadingFile';
export const ERROR_LOADING_DATA_EMPTY_DADA = 'errorLoadingDateEmptyData';
export const DATA_UPDATE_SUCCESS = 'dataUpdateSuccess';
export const DELETE_DATA = 'deleteData';
export const DATA_UPDATE_ERROR = 'dataUpdateError';
export const DATA_CREATE_SUCCESS = 'dataCreateSuccess';
