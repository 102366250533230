import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import theme from 'theme';
import Root from 'containers/Root';
import ProviderRedux from 'store';
import NotifierProvider from 'components/Notifier';
import RootAuth from 'containers/RootAuth/RootAuth';
import 'modules/i18n';
import useStyles from './styles';

function App() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ProviderRedux>
          <NotifierProvider>
            <RootAuth>
              <BrowserRouter>
                <Root />
              </BrowserRouter>
            </RootAuth>
          </NotifierProvider>
        </ProviderRedux>
      </ThemeProvider>
    </div>
  );
}

export default App;
