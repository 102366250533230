import API from 'API';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifier';
import * as typeNotifier from 'shared/typeNotifications';
import * as informationMessages from 'constants/infoMessages';

export const START_LOAD_UNBALANCE_DEVIATIONS = 'START_LOAD_UNBALANCE_DEVIATIONS';
export const SUCCESS_LOAD_UNBALANCE_DEVIATIONS = 'LOAD_UNBALANCE_DEVIATIONS';
export const ERROR_LOAD_UNBALANCE_DEVIATIONS = 'ERROR_LOAD_UNBALANCE_DEVIATIONS';
export const DRP_CHANGE_UNBALANCE_DEVIATIONS = 'DRP_CHANGE_UNBALANCE_DEVIATIONS';
export const DATE_PRESET_CHANGE_UNBALANCE_DEVIATIONS = 'DATE_PRESET_CHANGE_UNBALANCE_DEVIATIONS';
export const SUCCESS_LOAD_MIN_MAX_DATE_UNBALANCE_DEVIATIONS = 'SUCCESS_LOAD_MIN_MAX_DATE_UNBALANCE_DEVIATIONS';

export const loadUnbalanceDeviations = (typeChart, id, date, isFirstLoad, t) => (dispatch) => {
  dispatch({ type: START_LOAD_UNBALANCE_DEVIATIONS });
  API.getUnbalanceDeviations(typeChart, id, date)
    .then(({ data: { fractionOfDeviations, forecastExecution } }) => {
      dispatch({
        type: SUCCESS_LOAD_UNBALANCE_DEVIATIONS,
        forecastExecution,
        fractionOfDeviations,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_LOAD_UNBALANCE_DEVIATIONS,
      });
      dispatch(enqueueSnackbarAction({
        message: t(`infoMessages:${informationMessages.DATA_LOADING_ERROR}`),
        options: {
          variant: typeNotifier.ERROR,
        },
      }));
      console.error('LOAD_ALL_CHARTS');
    });
  if (isFirstLoad) {
    API.getMinMaxDateUnbalance(typeChart, id)
      .then(({ data: { min, max } }) => {
        dispatch({
          type: SUCCESS_LOAD_MIN_MAX_DATE_UNBALANCE_DEVIATIONS,
          min,
          max,
        });
      })
      .catch(() => {
        dispatch(enqueueSnackbarAction({
          message: 'Ошибка загрузки дат, за которые присутствуют данные',
          options: {
            variant: typeNotifier.ERROR,
          },
        }));
        console.error('LOAD_Min_Max_Date');
      });
  }
};
