import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { loadStations } from 'store/actions/stations';
import { getUserPermission } from 'store/actions/account';
import Routes from 'modules/Routes';
import Loader from 'pages/PageLoader';

import useStyles from './styles';

function Main() {
  const classes = useStyles();
  const { isLoading } = useSelector(({ stations }) => stations);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserPermission());
    dispatch(loadStations());
  }, [dispatch]);

  return (
    <Paper
      className={clsx(
        classes.root, {
          [classes.mobile]: isMobile
        }
      )}
    >
      {isLoading ? <Loader /> : <Routes />}
    </Paper>
  );
}

export default Main;
