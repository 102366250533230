import API from 'API';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifier';
import * as typeNotifier from 'shared/typeNotifications';
import * as informationMessages from 'constants/infoMessages';
import { CREATE_USER_TYPE } from 'pages/Administration/constants';

export const START_LOAD_ADMINISTRATION = 'START_LOAD_ADMINISTRATION';
export const SUCCESS_LOAD_ADMINISTRATION = 'SUCCESS_LOAD_ADMINISTRATION';
export const ERROR_LOAD_ADMINISTRATION = 'ERROR_LOAD_ADMINISTRATION';

export const SEARCH_CHANGE_ADMINISTRATION = 'SEARCH_CHANGE_ADMINISTRATION';
export const MULTI_SELECT_CHANGE_FILTER_ADMINISTRATION = 'MULTI_SELECT_CHANGE_FILTER_ADMINISTRATION';
export const APPLY_FILTER_ADMINISTRATION = 'APPLY_FILTER_ADMINISTRATION';
export const CLEAR_FILTER_ADMINISTRATION = 'CLEAR_FILTER_ADMINISTRATION';
export const SET_PAGINATION_ADMINISTRATION = 'SET_PAGINATION_ADMINISTRATION';
export const SET_SORTING_ADMINISTRATION = 'SET_SORTING_ADMINISTRATION';

export const CLEAR_ADMINISTRATION_ITEM = 'CLEAR_ADMINISTRATION_ITEM';

export const START_LOAD_ADMINISTRATION_ITEM = 'START_LOAD_ADMINISTRATION_ITEM';
export const SUCCESS_LOAD_ADMINISTRATION_ITEM = 'SUCCESS_LOAD_ADMINISTRATION_ITEM';
export const ERROR_LOAD_ADMINISTRATION_ITEM = 'ERROR_LOAD_ADMINISTRATION_ITEM';

export const START_CREATE_ADMINISTRATION_ITEM = 'START_CREATE_ADMINISTRATION_ITEM';
export const SUCCESS_CREATE_ADMINISTRATION_ITEM = 'SUCCESS_CREATE_ADMINISTRATION_ITEM';
export const ERROR_CREATE_ADMINISTRATION_ITEM = 'ERROR_CREATE_ADMINISTRATION_ITEM';

export const START_UPDATE_ADMINISTRATION_ITEM = 'START_UPDATE_ADMINISTRATION_ITEM';
export const SUCCESS_UPDATE_ADMINISTRATION_ITEM = 'SUCCESS_UPDATE_ADMINISTRATION_ITEM';
export const ERROR_UPDATE_ADMINISTRATION_ITEM = 'ERROR_UPDATE_ADMINISTRATION_ITEM';

export const START_DELETE_ADMINISTRATION_ITEM = 'START_DELETE_ADMINISTRATION_ITEM';
export const SUCCESS_DELETE_ADMINISTRATION_ITEM = 'SUCCESS_DELETE_ADMINISTRATION_ITEM';
export const ERROR_DELETE_ADMINISTRATION_ITEM = 'ERROR_DELETE_ADMINISTRATION_ITEM';
export const EDIT_ADMINISTRATION_USER_TYPE = 'EDIT_ADMINISTRATION_USER_TYPE';

export const ADMINISTRATION_DESTROY = 'ADMINISTRATION_DESTROY';

export const getAdministrationTable = (t, dto) => (dispatch) => {
  dispatch({ type: START_LOAD_ADMINISTRATION });
  API.getAdministrationTable(dto)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_ADMINISTRATION,
        data,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_LOAD_ADMINISTRATION,
      });
      dispatch(enqueueSnackbarAction({
        message: t(`infoMessages:${informationMessages.DATA_LOADING_ERROR}`),
        options: {
          variant: typeNotifier.ERROR,
        },
      }));
      console.error('OJ_TABLE');
    });
};

export const deleteAdministrationItem = (email, message) => (dispatch) => {
  dispatch({ type: START_DELETE_ADMINISTRATION_ITEM });
  API.deleteAdministrationItem(email)
    .then(() => {
      dispatch(enqueueSnackbarAction({
        message,
        options: {
          variant: typeNotifier.SUCCESS,
        },
      }));
      dispatch({
        type: SUCCESS_DELETE_ADMINISTRATION_ITEM
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_DELETE_ADMINISTRATION_ITEM,
      });
    });
};

export const getAdministrationItemByEmail = (email) => (dispatch) => {
  dispatch({ type: START_LOAD_ADMINISTRATION_ITEM });
  API.getAdministrationItemByEmail(email)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_ADMINISTRATION_ITEM,
        data,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_LOAD_ADMINISTRATION_ITEM,
      });
    });
};

export const createAdministrationItem = (param) => (dispatch) => {
  dispatch({ type: START_CREATE_ADMINISTRATION_ITEM });
  API.createAdministrationItem(param)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_CREATE_ADMINISTRATION_ITEM,
        data,
      });
      if (data?.result === CREATE_USER_TYPE.CREATED) {
        dispatch(enqueueSnackbarAction({
          message: `Пользователь ${data?.user?.displayName} успешно добавлен`,
          options: {
            variant: typeNotifier.SUCCESS,
          },
        }));
      }
    })
    .catch(() => {
      dispatch({
        type: ERROR_CREATE_ADMINISTRATION_ITEM,
      });
    });
};

export const updateAdministrationItem = (param, t, message) => (dispatch) => {
  dispatch({ type: START_UPDATE_ADMINISTRATION_ITEM });
  API.updateAdministrationItem(param)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_UPDATE_ADMINISTRATION_ITEM,
        data,
      });
      dispatch(enqueueSnackbarAction({
        message,
        options: {
          variant: typeNotifier.SUCCESS,
        },
      }));
    })
    .catch(() => {
      dispatch({
        type: ERROR_UPDATE_ADMINISTRATION_ITEM,
      });
      dispatch(enqueueSnackbarAction({
        message: t(`infoMessages:${informationMessages.DATA_UPDATE_ERROR}`),
        options: {
          variant: typeNotifier.ERROR,
        },
      }));
    });
};
