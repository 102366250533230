import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    boxShadow: '1px 2px 6px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: `${theme.palette.white} !important`,
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 8,
      height: '100%',
      left: 0,
      borderBottomLeftRadius: 4,
      borderTopLeftRadius: 4,
    },
    '& .snackbarMessage': {
      color: theme.palette.black90,
      fontSize: '1rem',
      maxWidth: 320,
    },
    '& .snackbarIconsClose': {
      color: theme.palette.grey.dark,
    },
    '& .snackbarIcons': {
      marginRight: 8,
    }
  },
  success: {
    '&::before': {
      backgroundColor: theme.palette.success.main,
    },
    '& .snackbarIcons': {
      color: theme.palette.success.main,
    }
  },
  info: {
    color: 'red',
    '&::before': {
      backgroundColor: theme.palette.info.main,
    },
    '& .snackbarIcons': {
      color: theme.palette.info.main,
    }
  },
  warning: {
    color: 'red',
    '&::before': {
      backgroundColor: theme.palette.warning.main,
    },
    '& .snackbarIcons': {
      color: theme.palette.warning.main,
    }
  },
  error: {
    color: 'red',
    '&::before': {
      backgroundColor: theme.palette.error.main,
    },
    '& .snackbarIcons': {
      color: theme.palette.error.main,
    }
  },
}));

export default useStyles;
