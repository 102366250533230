import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import breadcrumbs from 'utils/breadcrumbs';
import Can from './Can';

const PrivateRoute = ({
  component: Component, plants, permission, ...rest
}) => (
  <Route
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    render={(props) => {
      const { match: { params, path } } = props;
      const crumbs = breadcrumbs(params, path, { ...plants });
      return (
        <Can
          perform={permission}
          yes={() => (
          // eslint-disable-next-line react/jsx-props-no-spreading
            <Component crumbs={crumbs} {...props} />
          )}
          no={() => <Redirect to={{ pathname: '/no-access' }} />}
        />
      );
    }}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  permission: PropTypes.string.isRequired,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: {},
    path: PropTypes.string,
    url: PropTypes.string
  }),
  path: PropTypes.string.isRequired,
  params: PropTypes.objectOf(PropTypes.string),
  plants: PropTypes.shape({
    spp: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        fullName: PropTypes.string,
        latitude: PropTypes.string,
        longitude: PropTypes.string,
      })
    ),
    wpp: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        fullName: PropTypes.string,
        latitude: PropTypes.string,
        longitude: PropTypes.string,
      })
    ),
  }).isRequired,
};
PrivateRoute.defaultProps = {
  match: null,
  params: null,
};

export default PrivateRoute;
