import { isEmpty } from 'lodash';
import {
  startOfYear,
} from 'date-fns';
import { dateFormatISO } from 'shared/dateFormat';

import {
  START_LOAD_UNBALANCE_DEVIATIONS,
  SUCCESS_LOAD_UNBALANCE_DEVIATIONS,
  ERROR_LOAD_UNBALANCE_DEVIATIONS,
  DRP_CHANGE_UNBALANCE_DEVIATIONS,
  DATE_PRESET_CHANGE_UNBALANCE_DEVIATIONS,
  SUCCESS_LOAD_MIN_MAX_DATE_UNBALANCE_DEVIATIONS
} from 'store/actions/unbalanceDeviations';

const currentDate = new Date();
const defaultDate = {
  from: dateFormatISO(new Date(startOfYear(currentDate))),
  to: dateFormatISO(new Date(currentDate))
};

const initialState = {
  fractionOfDeviations: {
    nameChart: '',
    y1Name: '',
    xDateType: 0,
    data: []
  },
  executionForecast: {
    nameChart: '',
    y1Name: '',
    xDateType: 0,
    data: []
  },
  date: defaultDate,
  maxDate: null,
  minDate: null,
  isChangeDRP: false,
  isLoading: false,
  isLoaded: false,
  isError: false,
};
const unbalanceDeviations = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD_UNBALANCE_DEVIATIONS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS_LOAD_UNBALANCE_DEVIATIONS: {
      const {
        forecastExecution,
        fractionOfDeviations,
      } = action;
      return {
        ...state,
        fractionOfDeviations,
        forecastExecution,
        isLoading: false,
        isChangeDRP: false,
        isLoaded: !(isEmpty(fractionOfDeviations) || isEmpty(forecastExecution)),
      };
    }
    case ERROR_LOAD_UNBALANCE_DEVIATIONS: {
      return {
        ...state,
        isError: true,
      };
    }
    case DATE_PRESET_CHANGE_UNBALANCE_DEVIATIONS: {
      return {
        ...state,
        isChangeDRP: false,
        date: { ...state.date, ...action.date }
      };
    }
    case DRP_CHANGE_UNBALANCE_DEVIATIONS: {
      return {
        ...state,
        isChangeDRP: true,
        date: { ...state.date, ...action.date }
      };
    }
    case SUCCESS_LOAD_MIN_MAX_DATE_UNBALANCE_DEVIATIONS: {
      return {
        ...state,
        maxDate: action.max,
        minDate: action.min
      };
    }
    default:
      return state;
  }
};

export default unbalanceDeviations;
