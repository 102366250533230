import React from 'react';
import { AppBar, } from '@material-ui/core';

import Sidebar from 'containers/Sidebar';
import Main from 'containers/Main';
import Header from 'components/Header';
import useStyles from './styles';

function Root() {
  const classes = useStyles();

  return (
    <>
      <AppBar className={classes.appBar}>
        <Header />
      </AppBar>
      <Sidebar />
      <Main />
    </>
  );
}

export default Root;
