/* eslint-disable react/jsx-props-no-spreading */
import React, { lazy } from 'react';

import * as routes from 'shared/routes';
import * as typePowerPlant from 'shared/typePowerPlant';
import * as titles from 'constants/titles';
import {
  ADMINISTRATION_CREATE,
  ADMINISTRATION_EDIT,
  ADMINISTRATION_VIEW,
  BUSINESS_PLAN_VIEW,
  DASHBOARD_VIEW,
  FORECAST_VIEW,
  MAP_VIEW,
  OPERATION_JOURNAL_CREATE,
  OPERATION_JOURNAL_EDIT,
  OPERATION_JOURNAL_VIEW,
  POWER_BI_VIEW,
  RESTRICTIONS_JOURNAL_CREATE,
  RESTRICTIONS_JOURNAL_EDIT,
  RESTRICTIONS_JOURNAL_VIEW,
  UNBALANCE_VIEW,
} from 'constants/permissions';

const Dashboard = lazy(() => import('pages/Dashboard'));
const Charts = lazy(() => import('pages/Dashboard/Charts'));
const Users = lazy(() => import('pages/Users'));
const BusinessPlan = lazy(() => import('pages/BusinessPlan'));
const BusinessPlanDetails = lazy(() => import('pages/BusinessPlan/Details'));
const PowerBI = lazy(() => import('pages/PowerBI'));
const Unbalance = lazy(() => import('pages/Unbalance'));
const UnbalanceCharts = lazy(() => import('pages/Unbalance/Charts'));
const MapPowerPlants = lazy(() => import('pages/MapPowerPlants'));
const GeneralStatistic = lazy(() => import('pages/Dashboard/GeneralStatistic'));
const Forecast = lazy(() => import('pages/Forecast'));
const ForecastStations = lazy(() => import('pages/Forecast/Stations'));
const RestrictionsJournal = lazy(() => import('pages/RestrictionsJournal'));
const RestrictionsJournalCreateEdit = lazy(() => import('pages/RestrictionsJournal/CreateEdit'));
const RestrictionsJournalView = lazy(() => import('pages/RestrictionsJournal/View'));
const OperationalJournal = lazy(() => import('pages/OperationalJournal'));
const OperationalJournalCreateEdit = lazy(() => import('pages/OperationalJournal/CreateEdit'));
const OperationalJournalView = lazy(() => import('pages/OperationalJournal/View'));
const Administration = lazy(() => import('pages/Administration'));
const AdministrationCreateEdit = lazy(() => import('pages/Administration/CreateEdit'));
const AdministrationView = lazy(() => import('pages/Administration/View'));

const routeList = [
  {
    component: (props) => <Dashboard {...props} />,
    path: routes.DASHBOARD,
    name: titles.DASHBOARD,
    exact: true,
    permission: DASHBOARD_VIEW
  },
  {
    component: (props) => <Charts {...props} typePowerPlant={typePowerPlant.SPP} />,
    path: routes.CHARTS_SPP,
    name: titles.SPP,
    exact: true,
    permission: DASHBOARD_VIEW
  },
  {
    component: (props) => <Charts {...props} typePowerPlant={typePowerPlant.WPP} />,
    path: routes.CHARTS_WPP,
    name: titles.WPP,
    exact: true,
    permission: DASHBOARD_VIEW
  },
  {
    component: (props) => <GeneralStatistic {...props} />,
    path: routes.CHARTS_GENERAL_STATISTIC,
    name: titles.GENERAL_STATISTIC,
    exact: true,
    permission: DASHBOARD_VIEW
  },
  {
    component: (props) => <Users {...props} />,
    path: routes.USERS,
    name: 'Users',
    exact: true,
  },
  {
    component: (props) => <BusinessPlan {...props} />,
    path: routes.BUSINESS_PLAN,
    name: titles.BUSINESS_PLAN,
    exact: true,
    permission: BUSINESS_PLAN_VIEW,
  },
  {
    component: (props) => <BusinessPlanDetails {...props} />,
    path: routes.BUSINESS_PLAN_DETAILS,
    name: titles.WppSpp,
    exact: true,
    permission: BUSINESS_PLAN_VIEW,
  },
  {
    component: (props) => <PowerBI {...props} />,
    path: routes.POWER_BI,
    name: titles.POWER_BI,
    exact: true,
    permission: POWER_BI_VIEW,
  },
  {
    component: (props) => <Unbalance {...props} />,
    path: routes.UNBALANCE,
    name: titles.UNBALANCE,
    exact: true,
    permission: UNBALANCE_VIEW
  },
  {
    component: (props) => <UnbalanceCharts {...props} typePowerPlant={typePowerPlant.SPP} />,
    path: routes.UNBALANCE_SPP,
    name: titles.SPP,
    exact: true,
    permission: UNBALANCE_VIEW
  },
  {
    component: (props) => <UnbalanceCharts {...props} typePowerPlant={typePowerPlant.WPP} />,
    path: routes.UNBALANCE_WPP,
    name: titles.WPP,
    exact: true,
    permission: UNBALANCE_VIEW
  },
  {
    component: (props) => <MapPowerPlants {...props} />,
    path: routes.MAP,
    name: titles.MAP,
    exact: true,
    permission: MAP_VIEW
  },
  {
    component: (props) => <Forecast {...props} />,
    path: routes.FORECAST,
    name: titles.FORECAST,
    exact: true,
    permission: FORECAST_VIEW
  },
  {
    component: (props) => <ForecastStations {...props} typePowerPlant={typePowerPlant.SPP} />,
    path: routes.FORECAST_SPP,
    name: titles.SPP,
    exact: true,
    permission: FORECAST_VIEW
  },
  {
    component: (props) => <ForecastStations {...props} typePowerPlant={typePowerPlant.WPP} />,
    path: routes.FORECAST_WPP,
    name: titles.WPP,
    exact: true,
    permission: FORECAST_VIEW
  },
  {
    component: (props) => <RestrictionsJournal {...props} />,
    path: routes.RESTRICTIONS_JOURNAL,
    name: titles.RESTRICTIONS_JOURNAL,
    exact: true,
    permission: RESTRICTIONS_JOURNAL_VIEW,
  },
  {
    component: (props) => <RestrictionsJournalCreateEdit {...props} />,
    path: routes.RESTRICTIONS_JOURNAL_CREATE,
    name: titles.RESTRICTIONS_JOURNAL_CREATE,
    exact: true,
    permission: RESTRICTIONS_JOURNAL_CREATE,
  },
  {
    component: (props) => <RestrictionsJournalView {...props} />,
    path: routes.RESTRICTIONS_JOURNAL_VIEW_DETAILS,
    name: titles.RESTRICTIONS_JOURNAL_VIEW,
    exact: true,
    permission: RESTRICTIONS_JOURNAL_VIEW,
  },
  {
    component: (props) => <RestrictionsJournalCreateEdit {...props} />,
    path: routes.RESTRICTIONS_JOURNAL_VIEW_EDIT,
    name: titles.RESTRICTIONS_JOURNAL_EDIT,
    exact: true,
    permission: RESTRICTIONS_JOURNAL_EDIT,
  },
  {
    component: (props) => <OperationalJournal {...props} />,
    path: routes.OPERATION_JOURNAL,
    name: titles.OPERATION_JOURNAL,
    exact: true,
    permission: OPERATION_JOURNAL_VIEW
  },
  {
    component: (props) => <OperationalJournalCreateEdit {...props} />,
    path: routes.OPERATION_JOURNAL_CREATE,
    name: titles.OPERATION_JOURNAL_CREATE,
    exact: true,
    permission: OPERATION_JOURNAL_CREATE
  },
  {
    component: (props) => <OperationalJournalView {...props} />,
    path: routes.OPERATION_JOURNAL_VIEW,
    name: titles.OPERATION_JOURNAL_VIEW,
    exact: true,
    permission: OPERATION_JOURNAL_VIEW
  },
  {
    component: (props) => <OperationalJournalCreateEdit {...props} />,
    path: routes.OPERATION_JOURNAL_EDIT,
    name: titles.OPERATION_JOURNAL_EDIT,
    exact: true,
    permission: OPERATION_JOURNAL_EDIT
  },
  {
    component: (props) => <Administration {...props} />,
    path: routes.ADMINISTRATION,
    name: titles.ADMINISTRATION,
    exact: true,
    permission: ADMINISTRATION_VIEW
  },
  {
    component: (props) => <AdministrationCreateEdit {...props} />,
    path: routes.ADMINISTRATION_CREATE,
    name: titles.ADMINISTRATION_CREATE,
    exact: true,
    permission: ADMINISTRATION_CREATE
  },
  {
    component: (props) => <AdministrationCreateEdit {...props} />,
    path: routes.ADMINISTRATION_EDIT,
    name: titles.ADMINISTRATION_EDIT,
    exact: true,
    permission: ADMINISTRATION_EDIT
  },
  {
    component: (props) => <AdministrationView {...props} />,
    path: routes.ADMINISTRATION_VIEW,
    name: titles.ADMINISTRATION_VIEW,
    exact: true,
    permission: ADMINISTRATION_VIEW
  },
];

export default routeList;
