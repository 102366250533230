import API from 'API';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifier';
import * as typeNotifier from 'shared/typeNotifications';
import * as informationMessages from 'constants/infoMessages';

export const START_LOAD_CHARTS_LIVE_DATA = 'START_LOAD_CHARTS_LIVE_DATA';
export const SUCCESS_LOAD_CHARTS_LIVE_DATA = 'SUCCESS_LOAD_CHARTS_LIVE_DATA';
export const ERROR_LOAD_CHARTS_LIVE_DATA = 'ERROR_LOAD_CHARTS_LIVE_DATA';

export const loadChartsLiveData = (typeChart, id, t) => (dispatch) => {
  dispatch({ type: START_LOAD_CHARTS_LIVE_DATA });
  API.getChartsLiveData(typeChart, id)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_CHARTS_LIVE_DATA,
        data,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_LOAD_CHARTS_LIVE_DATA,
      });
      dispatch(enqueueSnackbarAction({
        message: t(`infoMessages:${informationMessages.DATA_LOADING_ERROR}`),
        options: {
          variant: typeNotifier.ERROR,
        },
      }));
      console.error('LOAD_ALL_CHARTS');
    });
};
