import API from 'API';

export const START_LOAD_STATIONS_FOR_MAP = 'START_LOAD_STATIONS_FOR_MAP';
export const SUCCESS_LOAD_STATIONS_FOR_MAP = 'SUCCESS_LOAD_STATIONS_FOR_MAP';

export const loadStationForMap = () => (dispatch) => {
  dispatch({ type: START_LOAD_STATIONS_FOR_MAP });
  API.getStationsForMap()
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_STATIONS_FOR_MAP,
        data,
      });
    })
    .catch(() => {
      console.error('LOAD_STATIONS_FOR_MAP');
    });
};
