import API from 'API';

export const START_LOAD_STATIONS = 'START_LOAD_STATIONS';
export const SUCCESS_LOAD_STATIONS = 'SUCCESS_LOAD_STATIONS';

export const loadStations = () => (dispatch) => {
  dispatch({ type: START_LOAD_STATIONS });
  API.getStations()
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_STATIONS,
        data,
      });
    })
    .catch(() => {
      console.error('LOAD_STATIONS');
    });
};
