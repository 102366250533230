import roles from './roles';

export const roleOptions = [
  {
    id: 1,
    name: roles.ADMIN
  },
  {
    id: 2,
    name: roles.SHIFT_SUPERVISOR
  },
  {
    id: 3,
    name: roles.POWER_ENGINEER
  },
  {
    id: 4,
    name: roles.EXPERT
  },
  {
    id: 5,
    name: roles.DISPATCHER
  },
  {
    id: 6,
    name: roles.USER
  },
];

export default roleOptions;
