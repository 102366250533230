const ru = {
  others: {
    generalStatistics: 'Общая статистика',
    wpp: 'Ветряные электростанции (ВЭС)',
    spp: 'Солнечные электростанции (СЭС)',
    allWpp: 'Все ВЭС',
    allSpp: 'Все СЭС',
    WppSpp: 'ВЭС-СЭС',
    wpp_botievo: 'Ботиевская ВЭС',
    wpp_orlovsk: 'Орловская ВЭС',
    wpp_promorsk1: 'Приморская ВЭС-1',
    wpp_primorsk2: 'Приморская ВЭС-2',
    spp_nikopol: 'Никопольская СЭС',
    spp_pokrovsk: 'Покровская СЭС',
    spp_trifanovsk: 'Трифановская СЭС',
    solarPowerPlants: 'Солнечные электростанции (СЭС)',
    windPowerPlants: 'Ветряные электростанции (ВЭС)',
    dashboard: 'Дашборд',
    powerBI: 'Power BI',
    unbalance: 'Небаланс',
    forecasts: 'Прогнозы',
    map: 'Карта',
    businessPlan: 'Бизнес план',
    users: 'Пользователи',
    restrictionsJournal: 'Журнал ограничений',
    restrictionsJournalCreate: 'Создание заявки',
    restrictionsJournalView: 'Просмотр заявки',
    restrictionsJournalEdit: 'Редактирование заявки',
    forecastAndActualProduction: 'Прогнозная и фактическая выработки (ВЭС, СЭС)',
    deviation: 'Отклонение',
    totalVariation: 'Суммарное отклонение',
    keyMetrics: 'Ключевые метрики',
    liveData: 'Данные в реальном времени',
    budgetVsFact: 'Заложено в план и фактические данные',
    generalUnbalanceTooltip: 'Положительные и отрицательные небалансы, а также их сумма',
    unbalanceOutOfRangeTooltip: 'Положительные и отрицательные небалансы выходящие за допустимый порог, а также их сумма',
    percentageOfPredictionsTooltip: 'Процент прогнозов которые сбылись в рамках допустимого порога отклонений',
    biasTooltip: 'Расхождения между данными прогноза и действительными (фактическими) данными',
    nmaeTooltip: 'Нормализованная средняя абсолютная ошибка',
    nrmseTooltip: 'Нормализованная среднеквадратичная ошибка',
    bPSuccess: 'Бизнес план успешно загружен',
    bpName: 'Название БП',
    bpYear: 'Год',
    bpAuthor: 'Автор',
    bpTemplate: 'Шаблон бизнес-плана',
    currentCapacity: 'Текущая мощность',
    projectCapacity: 'Проектная мощность',
    nestedListItems: 'Вложенные элементы списка',
    variationOfForecast: 'Отклонение прогнозной от фактической выработки',
    forecast: 'Прогнозный',
    actual: 'Фактический',
    station: 'Станция',
    namePlan: 'Бизнес план генерация ВЭС-СЭС',
    Production: 'Производство',
    EnergyAvailability: 'Доступность энергии',
    WindResource: 'Ресурс ветра',
    Insolation: 'Инсоляция',
    forecastHorizon: 'Горизонт прогноза',
    Average: 'В среднем',
    'Guaranteed Buyer': 'Гарантированный покупатель',
    Model: 'Модель',
    Fact: 'Фактические',
    startDateRDC: 'Дата и время получения команды от диспетчера РДЦ на ввод ограничения',
    powerCommandRestriction: 'Мощность команды ограничения,   +/-, МВт',
    powerBefore: 'Мощность станции до  введения ограничения, МВт',
    endDateRDC: 'Дата и время получения команды от диспетчера РДЦ на снятие ограничения',
    duration: 'Длительность ограничения, мин',
    powerAfter: 'Мощность станции после выхода на неограниченной мощности при существующих погодных условиях, МВт',
    lossesSCADA: 'Недоотпуск SCADA, МВтч',
    lossesCalculatedOrEtalon: 'Недоотпуск (расчётный или эталонный метод), МВт*ч',
    positiveImbalance: 'Положительный небаланс',
    negativeImbalance: 'Негативний небаланс',
    relativeUnbalanceIndex: 'Относительный показатель небаланса',
    openPowerBI: 'Открыть PowerBI в новой вкладке',
    OperationalJournal: 'Оперативный журнал',
    OperationalJournalCreate: 'Создать запись',
    OperationalJournalView: 'Просмотр записи',
    OperationalJournalEdit: 'Редактирование записи',
    OperationalJournalCreateRecord: 'Создание независимой записи',
    Administration: 'Администрирование',
    AdministrationCreate: 'Добавить нового пользователя',
    AdministrationEdit: 'Редактирование данных пользователя',
    AdministrationView: 'Просмотр данных пользователя',
    status: 'Статус',
    includedInClosedChain: 'Входит в закрытую цепочку',
    deletedStatus: 'Вычеркнутая',
    event: 'Событие',
    causeCategory: 'Категория причин',
    equipment: 'Оборудование',
    timestamp: 'Время',
    peopleId: 'ID пользователя',
    email: 'Почта',
    name: 'Имя',
    surname: 'Фамилия',
    role: 'Роль',
    location: 'Локация',
    admin: 'Администратор',
    shiftSupervisor: 'Начальник смены',
    powerEngineer: 'Инженер энергетик',
    expert: 'Эксперт',
    dispatcher: 'Диспетчер',
    user: 'Пользователь',
  },
  infoMessages: {
    noData: 'Нет данных',
    selectedPeriodNotMultipleCalendarWeek: 'Вы выбрали период, который не кратен календарной неделе. Показатели NMAE, NMRSE и bias будут отображены некорректно',
    loadingPleaseWait: 'Подождите, идет загрузка',
    notAccess: 'Эта страница не соответствует правам доступа',
    connectSupport: 'Обратитесь в службу поддержки для контроля доступа и отображения данных',
    dataLoadingError: 'Ошибка загрузки данных',
    errorLoadingFile: 'Ошибка загрузки файла',
    errorLoadingDateEmptyData: 'Ошибка загрузки дат, за которые присутствуют данные',
    errorLoadingListForecasters: 'Ошибка загрузки списка прогнозистов',
    updatingData: 'Обновление данных',
    fileError: 'Ошибка в файле',
    uploadFileVerification: 'Загрузить файл для проверки',
    overwriteData: 'Данные за период загрузки уже существуют в базе. Вы уверены что хотите перезаписать данные?',
    generalImbalance: 'Общий небаланс',
    totalDeviation: 'Суммарное отклонение',
    title404: 'Страница, которую Вы ищете, не существует или была удалена',
    desc404_1: 'Попробуйте начать свой путь с',
    desc404_2: 'главной страницы',
    dataUpdateSuccess: 'Изменения успешно добавлены',
    deleteData: 'Заявка {{id}} успешно удалена',
    dataUpdateError: 'Изменения не добавлены Обратитесь в службу поддержки для решения проблемы',
    dataCreateSuccess: 'Запись 100132  успешно создана',
  },
  btn: {
    ok: 'Ok',
    save: 'Сохранить',
    cancel: 'Отмена',
    downloadTemplate: 'Скачать шаблон',
    uploadBP: 'Загрузить БП',
    downloadBP: 'Скачать БП',
    delete: 'Удалить',
    edit: 'Редактировать',
    not: 'Нет',
    yes: 'Да',
    crossOut: 'Вычеркнуть',
  },
  unit: {
    mw: 'МВт',
    kWh: 'кВт*ч',
    wm2: 'Вт/м²',
    mc: 'м/с',
    mWH: 'мВт•ч',
    kilowatt: 'КВт',
    kilowattMeter2: 'кВт/м²',
    percentageFell: 'Процент прогнозов который попал в допустимые пределы',
    hour: 'час',
    hours: 'часа',
  },
  date: {
    today: 'Сегодня',
    lastWeek: 'Прошлая неделя',
    currentWeek: 'Текущая неделя',
    prevWeek: 'Предыдущая неделя',
    currentMonth: 'Текущий месяц',
    PrevMonth: 'Предыдущий месяц',
    lastYear: 'Прошлый год',
    currentYear: 'Текущий год',
    from: 'С',
    to: 'По',
    titleDRP: 'Выберите период',
    hour: 'час',
    hours: 'часа',
    calendar: 'Календарь',
    year: 'Год',
    weekToDate: 'С начала недели',
    monthToDate: 'С начала месяца',
    yearToDate: 'С начала года',
  }
};

export default ru;
