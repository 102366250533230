import {
  START_LOAD_RESTRICTIONS_JOURNAL,
  SUCCESS_LOAD_RESTRICTIONS_JOURNAL,
  ERROR_LOAD_RESTRICTIONS_JOURNAL,
  DATE_CHANGE_FILTER,
  MULTI_SELECT_CHANGE_FILTER,
  RADIO_SELECT_CHANGE_FILTER,
  APPLY_FILTER,
  CLEAR_FILTER,
  SET_PAGINATION,
  CLEAR_RESTRICTIONS_JOURNAL_ITEM,

  START_LOAD_RESTRICTIONS_JOURNAL_ITEM,
  SUCCESS_LOAD_RESTRICTIONS_JOURNAL_ITEM,
  ERROR_LOAD_RESTRICTIONS_JOURNAL_ITEM,

  START_CREATE_RESTRICTIONS_JOURNAL_ITEM,
  SUCCESS_CREATE_RESTRICTIONS_JOURNAL_ITEM,
  ERROR_CREATE_RESTRICTIONS_JOURNAL_ITEM,

  START_UPDATE_RESTRICTIONS_JOURNAL_ITEM,
  SUCCESS_UPDATE_RESTRICTIONS_JOURNAL_ITEM,
  ERROR_UPDATE_RESTRICTIONS_JOURNAL_ITEM,

  START_DELETE_RESTRICTIONS_JOURNAL_ITEM,
  SUCCESS_DELETE_RESTRICTIONS_JOURNAL_ITEM,
  ERROR_DELETE_RESTRICTIONS_JOURNAL_ITEM,

  START_LOAD_LOSSES_RESTRICTIONS_JOURNAL,
  SUCCESS_LOAD_LOSSES_RESTRICTIONS_JOURNAL,
  ERROR_LOAD_LOSSES_RESTRICTIONS_JOURNAL

} from 'store/actions/restrictionsJournal';
import { DEFAULT_PAGE, DEFAULT_LIMIT } from 'constants/pagination';

const DEFAULT_PAGINATION = {
  limit: DEFAULT_LIMIT,
  page: DEFAULT_PAGE
};

const defaultFilter = {
  stationsKey: [],
  date: { },
  requestStatus: 1,
  isChangeFilter: false,
  isEmptyFilter: true
};

const initialState = {
  dataTable: [],
  pagination: DEFAULT_PAGINATION,
  count: 0,
  filter: defaultFilter,
  isLoadingTable: false,
  isLoadedTable: false,
  isErrorTable: false,
  item: null,
  scadaLossesMw: '-',
  calculatedLossesMw: '-',

  isLoadingLosses: false,
  isLoadedLosses: false,
  isLoadedErrorLosses: false,

  isLoadingItem: false,
  isLoadedItem: false,
  isErrorItem: false,

  isUpdatingItem: false,
  isUpdatedItem: false,
  isUpdatedErrorItem: false,

  isCreatingItem: false,
  isCreatedItem: false,
  isCreatedErrorItem: false,

  isDeletingItem: false,
  isDeletedItem: false,
  isDeletedErrorItem: false,

};

const restrictionsJournal = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD_RESTRICTIONS_JOURNAL: {
      return {
        ...state,
        isLoadingTable: true,
      };
    }
    case SUCCESS_LOAD_RESTRICTIONS_JOURNAL: {
      return {
        ...state,
        dataTable: action.data?.logs,
        count: action.data?.count,
        isLoadingTable: false,
        isLoadedTable: true,
        isUpdatedItem: false,
        isCreatedItem: false,
        isDeletedItem: false
      };
    }
    case DATE_CHANGE_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          date: action.date,
          isChangeFilter: true,
          isEmptyFilter: false
        }
      };
    }
    case MULTI_SELECT_CHANGE_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          stationsKey: action.date,
          isChangeFilter: true,
          isEmptyFilter: false
        }
      };
    }
    case RADIO_SELECT_CHANGE_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          requestStatus: action.date,
          isChangeFilter: true,
          isEmptyFilter: false
        }
      };
    }
    case CLEAR_FILTER: {
      return {
        ...state,
        pagination: DEFAULT_PAGINATION,
        filter: defaultFilter
      };
    }
    case APPLY_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          isChangeFilter: false,
        },
        pagination: DEFAULT_PAGINATION,
      };
    }
    case ERROR_LOAD_RESTRICTIONS_JOURNAL: {
      return {
        ...state,
      };
    }
    case SET_PAGINATION: {
      return {
        ...state,
        pagination: action.data
      };
    }
    case START_LOAD_RESTRICTIONS_JOURNAL_ITEM:
    {
      return {
        ...state,
        isLoadingItem: true,
      };
    }
    case SUCCESS_LOAD_RESTRICTIONS_JOURNAL_ITEM:
    {
      return {
        ...state,
        item: action.data,
        isLoadingItem: false,
        isLoadedItem: true,
      };
    }
    case ERROR_LOAD_RESTRICTIONS_JOURNAL_ITEM: {
      return {
        ...state,
        isLoadedItem: true,
        isErrorItem: true,
      };
    }

    case START_CREATE_RESTRICTIONS_JOURNAL_ITEM: {
      return {
        ...state,
        isCreatingItem: true,
      };
    }
    case SUCCESS_CREATE_RESTRICTIONS_JOURNAL_ITEM: {
      return {
        ...state,
        isCreatingItem: false,
        isCreatedItem: true,
      };
    }
    case ERROR_CREATE_RESTRICTIONS_JOURNAL_ITEM: {
      return {
        ...state,
        isCreatedItem: true,
        isCreatedErrorItem: true,
      };
    }
    case START_UPDATE_RESTRICTIONS_JOURNAL_ITEM: {
      return {
        ...state,
        isUpdatingItem: true,
      };
    }
    case SUCCESS_UPDATE_RESTRICTIONS_JOURNAL_ITEM: {
      return {
        ...state,
        isUpdatingItem: false,
        isUpdatedItem: true,
      };
    }
    case ERROR_UPDATE_RESTRICTIONS_JOURNAL_ITEM: {
      return {
        ...state,
        ...state,
        isUpdatedItem: true,
        isUpdatedErrorItem: true,
      };
    }
    case START_DELETE_RESTRICTIONS_JOURNAL_ITEM: {
      return {
        ...state,
        isDeletingItem: true,
      };
    }
    case SUCCESS_DELETE_RESTRICTIONS_JOURNAL_ITEM: {
      return {
        ...state,
        isDeletingItem: false,
        isDeletedItem: true,
      };
    }
    case ERROR_DELETE_RESTRICTIONS_JOURNAL_ITEM: {
      return {
        ...state,
        isDeletedItem: true,
        isDeletedErrorItem: true,
      };
    }

    case CLEAR_RESTRICTIONS_JOURNAL_ITEM: {
      return {
        ...state,
        item: null,
      };
    }

    case START_LOAD_LOSSES_RESTRICTIONS_JOURNAL: {
      return {
        ...state,
        isLoadingLosses: true,
      };
    }
    case SUCCESS_LOAD_LOSSES_RESTRICTIONS_JOURNAL: {
      return {
        ...state,
        scadaLossesMw: action.data?.scadaLossesMw,
        calculatedLossesMw: action.data?.calculatedLossesMw,
        isLoadingLosses: false,
        isLoadedLosses: true,
      };
    }
    case ERROR_LOAD_LOSSES_RESTRICTIONS_JOURNAL: {
      return {
        ...state,
        isLoadedLosses: true,
        isLoadingLosses: false,
        isLoadedErrorLosses: true,
      };
    }

    default:
      return state;
  }
};

export default restrictionsJournal;
