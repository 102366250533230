import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const primarySb = grey[900];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 8px 0 0',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    justifyContent: 'flex-start',
    marginRight: 20,
    backgroundColor: theme.palette.black90,
    '& .MuiButtonBase-root': {
      width: '100%',
      color: theme.palette.white,
      transition: 'color 0.4s',
      '&:hover': {
        color: theme.palette.sidebar.active,
      },
    },
    '& .MuiIconButton-label': {
      minWidth: 48,
    },

  },
  logoContainer: {
    textDecoration: 'none',
    marginTop: '-10px',
    '@media (min-width: 850px)': {
      display: 'flex',
    },
    color: primarySb,
    '& svg': {
      width: 75,
    },
    '& .title': {
      position: 'relative',
      fontSize: 12,
      height: 12,
      whiteSpace: 'normal',
      lineHeight: 1.2,
      display: 'flex',
      alignItems: 'center',
      '@media (min-width: 850px)': {
        width: 100,
        paddingLeft: 30,
        height: 30,
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 15,
          width: 1,
          height: '100%',
          background: theme.palette.grey.middle,
        },
      },
    },
  },
  user: {
    '@media (max-width: 620px)': {
      position: 'absolute',
      bottom: 5
    },
  },
  accountBlock: {
    color: theme.palette.grey.middle,
    fontSize: '0.875rem',
    '@media (max-width: 620px)': {
      fontSize: '0.75rem',
      marginTop: '-20px',
    },
    '& .logout': {
      marginLeft: 6,
      '@media (min-width: 621px)': {
        marginLeft: 16,
      },
      color: theme.palette.grey.dark
    },
    '& .iconAccount': {
      marginLeft: 6,
      '@media (min-width: 620px)': {
        marginLeft: 14,
      },
      color: theme.palette.grey.dark
    },
    '& .MuiInputBase-input': {
      padding: '0 24px 0 0',
    },
    '& .MuiFormControl-root': {
      padding: '6px 0',
      '@media (min-width: 620px)': {
        marginRight: 30,
        position: 'relative',
        '&:after': {
          content: '""',
          position: 'absolute',
          height: '100%',
          width: 2,
          top: 0,
          background: theme.palette.grey.light,
          right: -15,
        },
      },
      '& label + .MuiInput-formControl': {
        margin: 0
      },
      '& .MuiInput-underline:before, & .MuiInput-underline:after ': {
        border: 'none'
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInput-underline:focus:not(.Mui-disabled):before ': {
        border: 'none'
      },
      '& .MuiSelect-root': {
        background: 'none'
      },
    }
  }
}));

export default useStyles;
