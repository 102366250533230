import {
  format, formatISO, isValid, getTime, getDay, differenceInMinutes
} from 'date-fns';
import { uk, ru, enGB } from 'date-fns/locale';
import getStorageLanguage from 'utils/getLang';

const locales = { uk, ru, en: enGB };
export const FULL_DATE_TIME = 'dd.MM.yyyy HH:mm';
export const MONTH = 'LLLL';
export const MONTH_SHORT_NAME = 'MMM';
export const FULL_DATE = 'dd.MM.yyyy';
export const FULL_TIME = 'HH:mm';
export const YEAR = 'yyyy';
export const TIME = 'HH:mm';

export const dateFormatISO = (date) => (isValid(new Date(date)) ? formatISO(new Date(date)) : '');

export const setDateFormat = (date, f) => (isValid(new Date(date)) ? format(new Date(date), f || FULL_DATE_TIME, { locale: locales[getStorageLanguage()] }) : '');

export const getTimeFromDate = (date) => (isValid(new Date(date)) ? getTime(new Date(date)) : '');

export const getDayOfTheWeek = (date) => (isValid(new Date(date)) ? getDay(new Date(date)) : '');

export const getMonthName = (monthNumber) => format(new Date(2000, monthNumber, 1), MONTH_SHORT_NAME, { locale: locales[getStorageLanguage()] });

export const getDifferenceInMinutes = (firstDate, secondDate) => ((isValid(new Date(firstDate)) && isValid(new Date(secondDate))) ? differenceInMinutes(new Date(firstDate), new Date(secondDate)) : '');

export const getWithoutTimeZone = (date) => (isValid(new Date(date)) ? new Date((date.getTime() - date.getTimezoneOffset() * 60000)) : '');
