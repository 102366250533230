import {
  START_LOAD_ADMINISTRATION,
  SUCCESS_LOAD_ADMINISTRATION,
  ERROR_LOAD_ADMINISTRATION,
  SEARCH_CHANGE_ADMINISTRATION,
  MULTI_SELECT_CHANGE_FILTER_ADMINISTRATION,
  APPLY_FILTER_ADMINISTRATION,
  CLEAR_FILTER_ADMINISTRATION,
  SET_PAGINATION_ADMINISTRATION,
  SET_SORTING_ADMINISTRATION,
  CLEAR_ADMINISTRATION_ITEM,

  START_LOAD_ADMINISTRATION_ITEM,
  SUCCESS_LOAD_ADMINISTRATION_ITEM,
  ERROR_LOAD_ADMINISTRATION_ITEM,

  START_CREATE_ADMINISTRATION_ITEM,
  SUCCESS_CREATE_ADMINISTRATION_ITEM,
  ERROR_CREATE_ADMINISTRATION_ITEM,

  START_UPDATE_ADMINISTRATION_ITEM,
  SUCCESS_UPDATE_ADMINISTRATION_ITEM,
  ERROR_UPDATE_ADMINISTRATION_ITEM,

  START_DELETE_ADMINISTRATION_ITEM,
  SUCCESS_DELETE_ADMINISTRATION_ITEM,
  ERROR_DELETE_ADMINISTRATION_ITEM,
  EDIT_ADMINISTRATION_USER_TYPE,

  ADMINISTRATION_DESTROY

} from 'store/actions/administration';
import { DEFAULT_PAGE, DEFAULT_LIMIT } from 'constants/pagination';
import { CREATE_USER_TYPE, headCells } from 'pages/Administration/constants';
import SORT_ORDER from 'constants/sortOrder';
import { mappingToAPI } from 'utils/mapping';

const OPTIONS_KEY = 'id';

const DEFAULT_PAGINATION = {
  limit: DEFAULT_LIMIT,
  page: DEFAULT_PAGE
};

const DEFAULT_SORT_TABLE = {
  sortOrder: SORT_ORDER.ASC,
  sortBy: headCells[1].key
};

const defaultFilter = {
  plantIds: [],
  roleIds: [],
  isChangeFilter: false,
  isEmptyFilter: true,
};

const initialState = {
  dataTable: [],
  pagination: DEFAULT_PAGINATION,
  count: 0,
  search: '',
  isChangeControlPage: false,
  isChangeFilterPanel: true,
  lastUpdatedAt: '',
  sorting: DEFAULT_SORT_TABLE,
  filter: defaultFilter,
  stationOJId: '',
  item: {
    id: '',
    plantIds: [],
    role: [],
    fullName: '',
    email: ''
  },
  createUserTypeResponse: 0,
  isLoadingTable: false,
  isLoadedTable: false,
  isErrorTable: false,

  isLoadingItem: false,
  isLoadedItem: false,
  isErrorItem: false,

  isUpdatingItem: false,
  isUpdatedItem: false,
  isUpdatedErrorItem: false,

  isCreatingItem: false,
  isCreatedItem: false,
  isCreatedErrorItem: false,

  isDeletingItem: false,
  isDeletedItem: false,
  isDeletedErrorItem: false,

};

const administration = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD_ADMINISTRATION: {
      return {
        ...state,
        isLoadingTable: true,
      };
    }
    case SUCCESS_LOAD_ADMINISTRATION: {
      return {
        ...state,
        dataTable: action.data?.items,
        count: action.data?.count,
        lastUpdatedAt: action.data?.lastUpdatedAt,
        createUserTypeResponse: 0,
        isChangeControlPage: false,
        isChangeFilterPanel: false,
        isLoadingTable: false,
        isLoadedTable: true,
        isUpdatedItem: false,
        isCreatedItem: false,
        isDeletedItem: false,
      };
    }
    case SEARCH_CHANGE_ADMINISTRATION: {
      return {
        ...state,
        search: action.data,
        isChangeControlPage: true,
        isChangeFilterPanel: false
      };
    }
    case MULTI_SELECT_CHANGE_FILTER_ADMINISTRATION: {
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.data.key]: mappingToAPI(action.data.items, OPTIONS_KEY),
          isChangeFilter: true,
          isEmptyFilter: false
        },
        isChangeFilterPanel: true,
      };
    }
    case CLEAR_FILTER_ADMINISTRATION: {
      return {
        ...state,
        pagination: DEFAULT_PAGINATION,
        filter: defaultFilter,
        isChangeFilterPanel: true,
      };
    }
    case APPLY_FILTER_ADMINISTRATION: {
      return {
        ...state,
        filter: {
          ...state.filter,
          isChangeFilter: false,
        },
        pagination: DEFAULT_PAGINATION,
        sorting: DEFAULT_SORT_TABLE,
      };
    }
    case ERROR_LOAD_ADMINISTRATION: {
      return {
        ...state,
      };
    }
    case SET_PAGINATION_ADMINISTRATION: {
      return {
        ...state,
        pagination: action.data,
        isChangeControlPage: true,
        isChangeFilterPanel: false
      };
    }
    case SET_SORTING_ADMINISTRATION: {
      return {
        ...state,
        sorting: action.data,
        isChangeControlPage: true,
        isChangeFilterPanel: false
      };
    }
    case START_LOAD_ADMINISTRATION_ITEM:
    {
      return {
        ...state,
        isLoadingItem: true,
      };
    }
    case SUCCESS_LOAD_ADMINISTRATION_ITEM:
    {
      return {
        ...state,
        item: action.data,
        isLoadingItem: false,
        isLoadedItem: true,
      };
    }
    case ERROR_LOAD_ADMINISTRATION_ITEM: {
      return {
        ...state,
        isLoadedItem: true,
        isErrorItem: true,
      };
    }

    case START_CREATE_ADMINISTRATION_ITEM: {
      return {
        ...state,
        isCreatingItem: true,
      };
    }
    case SUCCESS_CREATE_ADMINISTRATION_ITEM: {
      return {
        ...state,
        createUserTypeResponse: action.data?.result,
        isCreatingItem: false,
        isCreatedItem: action.data?.result === CREATE_USER_TYPE.CREATED,
      };
    }
    case ERROR_CREATE_ADMINISTRATION_ITEM: {
      return {
        ...state,
        isCreatedItem: true,
        isCreatedErrorItem: true,
      };
    }
    case START_UPDATE_ADMINISTRATION_ITEM: {
      return {
        ...state,
        isUpdatingItem: true,
      };
    }
    case SUCCESS_UPDATE_ADMINISTRATION_ITEM: {
      return {
        ...state,
        isUpdatingItem: false,
        isUpdatedItem: true,
      };
    }
    case ERROR_UPDATE_ADMINISTRATION_ITEM: {
      return {
        ...state,
        ...state,
        isUpdatedItem: true,
        isUpdatedErrorItem: true,
      };
    }
    case START_DELETE_ADMINISTRATION_ITEM: {
      return {
        ...state,
        isDeletingItem: true,
      };
    }
    case SUCCESS_DELETE_ADMINISTRATION_ITEM: {
      return {
        ...state,
        isDeletingItem: false,
        isDeletedItem: true,
      };
    }
    case ERROR_DELETE_ADMINISTRATION_ITEM: {
      return {
        ...state,
        isDeletedItem: true,
        isDeletedErrorItem: true,
      };
    }

    case CLEAR_ADMINISTRATION_ITEM: {
      return {
        ...state,
        item: null,
      };
    }
    case EDIT_ADMINISTRATION_USER_TYPE: {
      return {
        ...state,
        createUserTypeResponse: action.data,
      };
    }
    case ADMINISTRATION_DESTROY: {
      return {
        ...state,
        isChangeFilterPanel: true,
      };
    }
    default:
      return state;
  }
};

export default administration;
