import API from 'API';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifier';
import * as typeNotifier from 'shared/typeNotifications';
import * as informationMessages from 'constants/infoMessages';

export const START_LOAD_GENERAL_UNBALANCE = 'START_LOAD_GENERAL_UNBALANCE';
export const SUCCESS_LOAD_GENERAL_UNBALANCE = 'SUCCESS_LOAD_GENERAL_UNBALANCE';
export const ERROR_LOAD_GENERAL_UNBALANCE = 'ERROR_LOAD_GENERAL_UNBALANCE';

export const loadGeneralUnbalance = (t) => (dispatch) => {
  dispatch({ type: START_LOAD_GENERAL_UNBALANCE });
  API.getGeneralUnbalance()
    .then(([
      { data: dataWpp },
      { data: dataSpp },
    ]) => {
      dispatch({
        type: SUCCESS_LOAD_GENERAL_UNBALANCE,
        dataWpp,
        dataSpp,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_LOAD_GENERAL_UNBALANCE,
      });
      dispatch(enqueueSnackbarAction({
        message: t(`infoMessages:${informationMessages.DATA_LOADING_ERROR}`),
        options: {
          variant: typeNotifier.ERROR,
        },
      }));
      console.error('LOAD_ALL_CHARTS');
    });
};
