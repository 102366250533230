import {
  START_LOAD_OJ,
  SUCCESS_LOAD_OJ,
  ERROR_LOAD_OJ,
  DATE_CHANGE_FILTER_OJ,
  MULTI_SELECT_CHANGE_FILTER_OJ,
  CHECKBOX_CHANGE_FILTER_OJ,
  APPLY_FILTER_OJ,
  CLEAR_FILTER_OJ,
  SET_PAGINATION_OJ,
  SET_SORTING_OJ,
  CLEAR_OJ_ITEM,
  SELECT_CHANGE_PLANT_OJ,

  START_LOAD_OJ_ITEM,
  SUCCESS_LOAD_OJ_ITEM,
  ERROR_LOAD_OJ_ITEM,

  START_CREATE_OJ_ITEM,
  SUCCESS_CREATE_OJ_ITEM,
  ERROR_CREATE_OJ_ITEM,

  START_UPDATE_OJ_ITEM,
  SUCCESS_UPDATE_OJ_ITEM,
  ERROR_UPDATE_OJ_ITEM,

  START_DELETE_OJ_ITEM,
  SUCCESS_DELETE_OJ_ITEM,
  ERROR_DELETE_OJ_ITEM,

  START_LOAD_FILTER_OPTIONS_OJ,
  SUCCESS_LOAD_FILTER_OPTIONS_OJ,

  SUCCESS_LOAD_PLANT_OJ,

} from 'store/actions/operationalJournal';
import { DEFAULT_PAGE, DEFAULT_LIMIT } from 'constants/pagination';
import { headCells } from 'pages/OperationalJournal/constants';
import SORT_ORDER from 'constants/sortOrder';

import { mappingToAPI } from 'pages/OperationalJournal/components/TableWrap/utils';

const OPTIONS_KEY = 'id';

const DEFAULT_PAGINATION = {
  limit: DEFAULT_LIMIT,
  page: DEFAULT_PAGE
};

const DEFAULT_SORT_TABLE = {
  sortOrder: SORT_ORDER.DESC,
  sortBy: headCells[1].key
};

const DEFAULT_PLANT_ID = 4;

const defaultFilter = {
  equipmentIds: [],
  eventCategoryIds: [],
  eventIds: [],
  date: {},
  equipmentOptions: null,
  eventCategoryOptions: [],
  eventOptions: [],
  statusOJogIds: [],
  isChangeFilter: false,
  isEmptyFilter: true,
};

const initialState = {
  dataTable: [],
  pagination: DEFAULT_PAGINATION,
  count: 0,
  lastUpdatedAt: '',
  sorting: DEFAULT_SORT_TABLE,
  filter: defaultFilter,
  plantOptions: [],
  stationOJId: '',
  item: null,
  topicLogs: [],

  isLoadingTable: false,
  isLoadedTable: false,
  isErrorTable: false,

  isLoadingItem: false,
  isLoadedItem: false,
  isErrorItem: false,

  isUpdatingItem: false,
  isUpdatedItem: false,
  isUpdatedErrorItem: false,

  isCreatingItem: false,
  isCreatedItem: false,
  isCreatedErrorItem: false,

  isDeletingItem: false,
  isDeletedItem: false,
  isDeletedErrorItem: false,

};

const operationalJournal = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD_OJ: {
      return {
        ...state,
        isLoadingTable: true,
      };
    }
    case SUCCESS_LOAD_OJ: {
      return {
        ...state,
        dataTable: action.data?.items,
        count: action.data?.count,
        lastUpdatedAt: action.data?.lastUpdatedAt,
        isLoadingTable: false,
        isLoadedTable: true,
        isUpdatedItem: false,
        isCreatedItem: false,
        isDeletedItem: false,
      };
    }
    case SUCCESS_LOAD_PLANT_OJ: {
      const { stationId = [] } = action;
      return {
        ...state,
        plantOptions: stationId,
        stationOJId: stationId.length > DEFAULT_PLANT_ID ? DEFAULT_PLANT_ID : stationId[0]?.id // дані є тільки по 4-станції action.data.length && action.data[0].id
      };
    }
    case SELECT_CHANGE_PLANT_OJ: {
      return {
        ...state,
        pagination: DEFAULT_PAGINATION,
        filter: defaultFilter,
        stationOJId: action.data
      };
    }
    case DATE_CHANGE_FILTER_OJ: {
      return {
        ...state,
        filter: {
          ...state.filter,
          date: action.date,
          isChangeFilter: true,
          isEmptyFilter: false
        }
      };
    }
    case MULTI_SELECT_CHANGE_FILTER_OJ: {
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.data.key]: mappingToAPI(action.data.items, OPTIONS_KEY),
          isChangeFilter: true,
          isEmptyFilter: false
        }
      };
    }
    case CHECKBOX_CHANGE_FILTER_OJ: {
      const ids = state.filter.statusOJogIds.includes(action.data)
        ? state.filter.statusOJogIds.filter((item) => item !== action.data)
        : [...state.filter.statusOJogIds, action.data];
      return {
        ...state,
        filter: {
          ...state.filter,
          statusOJogIds: ids,
          isChangeFilter: true,
          isEmptyFilter: false
        }
      };
    }
    case CLEAR_FILTER_OJ: {
      return {
        ...state,
        pagination: DEFAULT_PAGINATION,
        filter: defaultFilter
      };
    }
    case APPLY_FILTER_OJ: {
      return {
        ...state,
        filter: {
          ...state.filter,
          isChangeFilter: false,
        },
        pagination: DEFAULT_PAGINATION,
        sorting: DEFAULT_SORT_TABLE,
      };
    }
    case ERROR_LOAD_OJ: {
      return {
        ...state,
      };
    }
    case SET_PAGINATION_OJ: {
      return {
        ...state,
        pagination: action.data,
      };
    }
    case SET_SORTING_OJ: {
      return {
        ...state,
        sorting: action.data
      };
    }
    case START_LOAD_OJ_ITEM:
    {
      return {
        ...state,
        isLoadingItem: true,
      };
    }
    case SUCCESS_LOAD_OJ_ITEM:
    {
      return {
        ...state,
        item: action.data.log,
        topicLogs: action.data.topicLogs,
        isLoadingItem: false,
        isLoadedItem: true,
      };
    }
    case ERROR_LOAD_OJ_ITEM: {
      return {
        ...state,
        isLoadedItem: true,
        isErrorItem: true,
      };
    }

    case START_CREATE_OJ_ITEM: {
      return {
        ...state,
        isCreatingItem: true,
      };
    }
    case SUCCESS_CREATE_OJ_ITEM: {
      return {
        ...state,
        isCreatingItem: false,
        isCreatedItem: true,
      };
    }
    case ERROR_CREATE_OJ_ITEM: {
      return {
        ...state,
        isCreatedItem: true,
        isCreatedErrorItem: true,
      };
    }
    case START_UPDATE_OJ_ITEM: {
      return {
        ...state,
        isUpdatingItem: true,
      };
    }
    case SUCCESS_UPDATE_OJ_ITEM: {
      return {
        ...state,
        isUpdatingItem: false,
        isUpdatedItem: true,
      };
    }
    case ERROR_UPDATE_OJ_ITEM: {
      return {
        ...state,
        ...state,
        isUpdatedItem: true,
        isUpdatedErrorItem: true,
      };
    }
    case START_DELETE_OJ_ITEM: {
      return {
        ...state,
        isDeletingItem: true,
      };
    }
    case SUCCESS_DELETE_OJ_ITEM: {
      return {
        ...state,
        isDeletingItem: false,
        isDeletedItem: true,
      };
    }
    case ERROR_DELETE_OJ_ITEM: {
      return {
        ...state,
        isDeletedItem: true,
        isDeletedErrorItem: true,
      };
    }

    case CLEAR_OJ_ITEM: {
      return {
        ...state,
        item: null,
      };
    }

    case START_LOAD_FILTER_OPTIONS_OJ: {
      return {
        ...state,
        isLoadingEquipment: true,
      };
    }
    case SUCCESS_LOAD_FILTER_OPTIONS_OJ: {
      const {
        equipmentOptions,
        eventCategoryOptions,
        eventOptions,
      } = action;
      return {
        ...state,
        filter: {
          ...state.filter,
          equipmentOptions, //equipmentOptions.map(({ id, name }) => ({ key: id, value: name })),
          eventCategoryOptions, //: eventCategoryOptions.map(({ id, name }) => ({ key: id, value: name })),
          eventOptions, //: eventOptions.map(({ id, name }) => ({ key: id, value: name })),
        }
      };
    }

    default:
      return state;
  }
};

export default operationalJournal;
