import roleOptions from 'constants/roleOptions';
import rolesEnum from 'constants/roles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import rules from './rules';

const getUserRoles = (roleId) => roleOptions.filter(({ id }) => id === roleId).map(({ name }) => name);

const check = ({ role, action, data }) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

const CanWithRole = ({
  perform,
  data,
  roles,
  no,
  yes,
  render,
}) => {
  const hasPermission = (role) => check({ role, action: perform, data });

  const acceptRole = roles?.some(hasPermission);
  if (render) {
    return render(acceptRole);
  }

  return acceptRole ? yes() : no();
};

CanWithRole.defaultProps = {
  yes: () => null,
  no: () => null,
};
CanWithRole.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  yes: PropTypes.func,
  no: PropTypes.func,
  perform: PropTypes.string,
};

export { CanWithRole };
export default connect(({ account }) => ({ roles: getUserRoles(account.roleId ? account.roleId : rolesEnum.ADMIN_ID) }))(CanWithRole);
