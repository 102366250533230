import queryString from 'query-string';
import * as typePP from 'shared/typePowerPlant';
import * as typeAlert from 'shared/typeAlert';
import { Validator } from 'jsonschema';
import { Schemas, showErrorMessageInConsole } from 'modules/schema';
import SORT_ORDER from 'constants/sortOrder';
import get from 'lodash/get';
import axiosApiInstance from './axiosSettings';

const v = new Validator();

let API_DOMAIN = 'anl001-api-int.k8s-app-dev.dtek.cloud';
const isLocalEnv = Boolean(process.env.REACT_APP_API_URL);
if (isLocalEnv) {
  API_DOMAIN = process.env.REACT_APP_API_URL;
}

const API_URL = `https://${API_DOMAIN}/api`;
const API = {
  healthCheck: async () => {
    try {
      return await axiosApiInstance.get(
        'https://anl001-api-app-int.azurewebsites.net/HealthCheck/AuthTest'
      );
    } catch (error) {
      console.error(error);
    }
  },
  getUsers: async () => {
    try {
      return await axiosApiInstance.get(`${API_URL}/users`);
    } catch (error) {
      console.error(error);
    }
  },
  getAuth: async () => {
    try {
      return await axiosApiInstance.get(`${API_URL}/Auth`);
    } catch (error) {
      console.error(error);
    }
  },
  getDataAllCharts: async (typeChart, id, date = {}) => {
    const partUrlParams = `from=${encodeURIComponent(date.from)}&to=${encodeURIComponent(date.to)}`;
    try {
      const requestUrl = id
        ? `${API_URL}/${typeChart}?id=${id}&${partUrlParams}`
        : `${API_URL}/${typeChart}?${partUrlParams}`;
      return await axiosApiInstance.get(requestUrl);
    } catch (error) {
      console.log(error);
    }
  },
  getChartsLiveData: async (typeChart, id) => {
    try {
      const params = typeChart === typePP.WPP
        ? 'IncludeWindSpeedAndPower=true'
        : 'IncludeInsolationAndOutput=true';
      const requestUrl = id
        ? `${API_URL}/${typeChart}/live?${params}&IncludeNameValue=true&PlantIds=${id}`
        : `${API_URL}/${typeChart}/live?${params}&IncludeNameValue=true`;
      return await axiosApiInstance.get(requestUrl);
    } catch (error) {
      console.log(error);
    }
  },
  getStations: async () => {
    try {
      const response = await axiosApiInstance.get(`${API_URL}/Plant/All`);
      const validate = v.validate(response.data, Schemas.PLANT_ALL, { nestedErrors: true });
      if (!validate.valid && isLocalEnv) {
        showErrorMessageInConsole(validate.schema?.description, validate.toString());
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  getDataCommonMetrics: async (date = {}) => {
    try {
      return await axiosApiInstance.get(`${API_URL}/Plant/CommonMetrics?from=${encodeURIComponent(date.from)}&to=${encodeURIComponent(date.to)}`);
    } catch (error) {
      console.log(error);
    }
  },
  getUnbalance: async () => {
    try {
      const response = await axiosApiInstance.get('/fake/all-charts.json');
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  getChartsUnbalance: async (typeChart, id, date = {}) => {
    try {
      const requestUrl = id
        ? `${API_URL}/${typeChart}/Unbalance?id=${id}&from=${encodeURIComponent(date.from)}&to=${encodeURIComponent(date.to)}`
        : `${API_URL}/${typeChart}/Unbalance`;
      return await axiosApiInstance.get(requestUrl);
    } catch (error) {
      console.log(error);
    }
  },
  getMinMaxDateUnbalance: async (typeChart, id,) => {
    try {
      return await axiosApiInstance.get(`${API_URL}/${typeChart}/EnergyForecastPeriod?id=${id}`);
    } catch (error) {
      console.log(error);
    }
  },
  getGeneralUnbalance: () => Promise.all([
    API.getChartsUnbalance(typePP.WPP, 0),
    API.getChartsUnbalance(typePP.SPP, 0),
  ]),
  getUnbalanceDeviations: async (typeChart, id, date) => {
    const requestUrl = id
      ? `${API_URL}/${typeChart}/UnbalanceDeviations?id=${id}&from=${encodeURIComponent(date.from)}&to=${encodeURIComponent(date.to)}`
      : `${API_URL}/${typeChart}/UnbalanceDeviations?from=${encodeURIComponent(date.from)}&to=${encodeURIComponent(date.to)}`;
    try {
      return await axiosApiInstance.get(requestUrl);
    } catch (error) {
      console.log(error);
    }
  },
  uploadFile: async (file) => {
    try {
      return await axiosApiInstance.post(`${API_URL}/BusinessPlan/worksheet`, file, { contentType: 'multipart/form-data' });
    } catch (error) {
      console.log(error);
    }
  },
  updateFile: async (id) => {
    try {
      return await axiosApiInstance.post(`${API_URL}/BusinessPlan/UpdateWorksheet?id=${id}`, null);
    } catch (error) {
      console.log(error);
    }
  },
  downloadFile: async (id, typeReport = null) => {
    const requestUrl = (typeReport === typeAlert.BP_ERROR)
      ? `${API_URL}/BusinessPlan/ShowDocErrors?id=${id}`
      : `${API_URL}/BusinessPlan/worksheet/${id || 'template'}`;
    try {
      return await axiosApiInstance.get(requestUrl, { responseType: 'arraybuffer' });
    } catch (error) {
      console.log(error);
    }
  },
  getDataBusinessPlan: async (id) => {
    const requestUrl = id
      ? `${API_URL}/BusinessPlan/details/${id}`
      : `${API_URL}/BusinessPlan`;
    try {
      const response = await axiosApiInstance.get(requestUrl);
      const validate = v.validate(response.data, id ? Schemas.BUSINESS_PLAN_DETAILS : Schemas.BUSINESS_PLAN_TABLE, { nestedErrors: true });
      if (!validate.valid && isLocalEnv) {
        showErrorMessageInConsole(validate.schema?.description, validate.toString());
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  getForecastList: async (typeStation) => {
    try {
      const response = await axiosApiInstance.get(`${API_URL}/${typeStation}/ForecastProviders`);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  getForecastMetrics: async ({
    typeStation, providerIds, plantId, date, horizon
  }) => {
    const params = `?plantId=${plantId}&providerIds=${providerIds}&horizon=${horizon}&from=${encodeURIComponent(date.from)}&to=${encodeURIComponent(date.to)}`;
    try {
      const response = await axiosApiInstance.get(`${API_URL}/${typeStation}/Forecast${params}`);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  getRestrictionsJournalTable: async (props) => {
    try {
      const param = queryString.stringify({
        limit: props?.pagination?.limit,
        page: get(props.pagination, 'page', 0) + 1,
        plantIds: props?.stationsKey,
        requestStatus: props?.requestStatus,
        from: props?.date?.from ? props?.date?.from : undefined,
        to: props?.date?.to ? props?.date?.to : undefined,
      });

      const response = await axiosApiInstance.get(`${API_URL}/RestrictionLog?${param}`);

      const validate = v.validate(response.data, Schemas.RESTRICTION_LOG, { nestedErrors: true });
      if (!validate.valid && isLocalEnv) {
        showErrorMessageInConsole(validate.description, validate.toString());
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  getRestrictionsJournalItemById: async (id) => {
    try {
      return await axiosApiInstance.get(`${API_URL}/RestrictionLog/${id}`);
    } catch (error) {
      console.log(error);
    }
  },
  createRestrictionsJournalItem: async (props) => {
    try {
      return await axiosApiInstance.post(`${API_URL}/RestrictionLog`,
        props);
    } catch (error) {
      console.log(error);
    }
  },
  updateRestrictionsJournalItem: async (props) => {
    try {
      return await axiosApiInstance.patch(`${API_URL}/RestrictionLog`,
        props);
    } catch (error) {
      console.log(error);
    }
  },
  deleteRestrictionsJournalItem: async (id) => {
    try {
      return await axiosApiInstance.delete(`${API_URL}/RestrictionLog/?id=${id}`);
    } catch (error) {
      console.log(error);
    }
  },

  getLossesSum: async (props) => {
    try {
      const param = queryString.stringify({
        PlantIds: props?.plantId,
        RequestStatus: 1,
        From: props?.date?.from ? props?.date?.from : undefined,
        To: props?.date?.to ? props?.date?.to : undefined,
      });

      const response = await axiosApiInstance.get(`${API_URL}/RestrictionLog/LossesSum?${param}`);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  getStationsForMap: async () => {
    try {
      const response = await axiosApiInstance.get(`${API_URL}/Plant/AllForMap`);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  getOperationalJournalTable: async (props) => {
    try {
      const param = queryString.stringify({
        limit: props?.pagination?.limit,
        page: get(props.pagination, 'page', 0) + 1,
        plantId: props?.stationOJId,
        statuses: props?.statusOJogIds,
        from: props?.date?.from ? props?.date?.from : undefined,
        to: props?.date?.to ? props?.date?.to : undefined,
        equipmentIds: props?.equipmentIds,
        eventCategoryIds: props?.eventCategoryIds,
        eventIds: props?.eventIds,
        sortOrder: props?.sorting?.sortOrder === SORT_ORDER.ASC ? 0 : 1,
        sortBy: props?.sorting?.sortBy
      });

      const response = await axiosApiInstance.get(`${API_URL}/OperationalLog?${param}`);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  deleteOperationalJournalItem: async (id) => {
    try {
      return await axiosApiInstance.delete(`${API_URL}/OperationalLog?id=${id}`);
    } catch (error) {
      console.log(error);
    }
  },
  getOperationalJournalEquipment: async (id) => {
    try {
      const response = await axiosApiInstance.get(`${API_URL}/PlantEquipment?plantId=${id}`);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  getOperationalJournalEventCategory: async () => {
    try {
      const response = await axiosApiInstance.get(`${API_URL}/OperationalLog/eventCategory`);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  getOperationalJournalEvent: async () => {
    try {
      const response = await axiosApiInstance.get(`${API_URL}/OperationalLog/event`);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  getOJPlants: async () => {
    try {
      const response = await axiosApiInstance.get(`${API_URL}/OperationalLog/Plants`);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  getOJFilterOptions: (id) => Promise.allSettled([
    API.getOperationalJournalEquipment(id),
    API.getOperationalJournalEventCategory(),
    API.getOperationalJournalEvent()]),
  getOJItemById: async (id) => {
    try {
      return await axiosApiInstance.get(`${API_URL}/OperationalLog/LogPageContent?LogId=${id}`);
    } catch (error) {
      console.log(error);
    }
  },
  createOJItem: async (props) => {
    try {
      return await axiosApiInstance.put(`${API_URL}/OperationalLog`,
        props);
    } catch (error) {
      console.log(error);
    }
  },
  updateOJItem: async (props) => {
    try {
      return await axiosApiInstance.patch(`${API_URL}/OperationalLog`,
        props);
    } catch (error) {
      console.log(error);
    }
  },
  getAdministrationTable: async (props) => {
    try {
      const param = queryString.stringify({
        limit: props?.pagination?.limit,
        page: get(props?.pagination, 'page', 0) + 1,
        search: props?.search,
        plantIds: props?.plantIds,
        roleIds: props?.roleIds,
        sortOrder: props?.sorting?.sortOrder === SORT_ORDER.ASC ? 0 : 1,
        sortBy: props?.sorting?.sortBy
      });
      const response = await axiosApiInstance.get(`${API_URL}/administration/User?${param}`);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  deleteAdministrationItem: async (email) => {
    try {
      return await axiosApiInstance.delete(`${API_URL}/administration/User?email=${email}`);
    } catch (error) {
      console.log(error);
    }
  },
  getAdministrationItemByEmail: async (email) => {
    try {
      return await axiosApiInstance.get(`${API_URL}/administration/User/${email}`);
    } catch (error) {
      console.log(error);
    }
  },
  createAdministrationItem: async (props) => {
    try {
      return await axiosApiInstance.put(`${API_URL}/administration/User`, props);
    } catch (error) {
      console.log(error);
    }
  },
  updateAdministrationItem: async (props) => {
    try {
      return await axiosApiInstance.patch(`${API_URL}/administration/User`,
        props);
    } catch (error) {
      console.log(error);
    }
  },
};

export default API;
