import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    margin: '100px 25px 25px 97px',
    minHeight: 'calc(100vh - 125px)',
    textAlign: 'center',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    position: 'relative',
  },
  mobile: {
    margin: '100px 25px 25px 25px',
  }
}));

export default useStyles;
