const initialState = {
  title: 'Initial State Users',
  users: [],
};

const users = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default users;
