import {
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
  REMOVE_ALL_SNACKBAR
} from 'store/actions/notifier';

const initialState = {
  notifications: [],
};

const notifier = (state = initialState, action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR: {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };
    }
    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key
        ),
      };
    case REMOVE_ALL_SNACKBAR:
      return {
        ...state,
        notifications: [],
      };
    default:
      return state;
  }
};

export default notifier;
