import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoSvg } from 'assets/logo.svg';
import useStyles from './styles';

function Loader() {
  const classes = useStyles();
  const { t } = useTranslation(['infoMessages']);
  return (
    <div className={classes.container}>
      <div className={classes.loader}>
        <div className={classes.pacman} />
        <div className={classes.bols}>
          <div />
          <div />
          <div />
        </div>
        <LogoSvg className={classes.logo} />
        <div className={classes.text}>{t('loadingPleaseWait')}</div>
      </div>
    </div>
  );
}

export default Loader;
