import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import * as routes from 'shared/routes';
import * as titles from 'constants/titles';
import { ReactComponent as DashboardIcon } from 'containers/Sidebar/assets/dashboard.svg';
import { ReactComponent as SettingsIcon } from 'containers/Sidebar/assets/SettingsMenu.svg';
import { ReactComponent as UnbalanceIcon } from 'containers/Sidebar/assets/unbalance.svg';
import { ReactComponent as BusinessPlanIcon } from 'containers/Sidebar/assets/BusinessPlan.svg';
import { ReactComponent as ForecastIcon } from 'containers/Sidebar/assets/forecast.svg';
import { ReactComponent as OperationalJournalIcon } from 'containers/Sidebar/assets/OperationalJournal.svg';
import { ReactComponent as AdministrationPeopleIcon } from 'containers/Sidebar/assets/People.svg';
import Can from 'containers/RoleModel';
import {
  ADMINISTRATION_VIEW,
  BUSINESS_PLAN_VIEW,
  DASHBOARD_VIEW,
  FORECAST_VIEW,
  MAP_VIEW,
  OPERATION_JOURNAL_VIEW,
  POWER_BI_VIEW,
  RESTRICTIONS_JOURNAL_VIEW,
  UNBALANCE_VIEW
} from 'constants/permissions';

import useStyles from './styles';

function Sidebar() {
  const classes = useStyles();
  const { open } = useSelector(({ sidebar }) => sidebar);
  const { isLoadedPermission } = useSelector(({ account }) => account);

  const { t } = useTranslation(['others']);

  const mainMenu = [
    {
      label: titles.DASHBOARD,
      icon: DashboardIcon,
      routerLink: routes.DASHBOARD,
      perform: DASHBOARD_VIEW
    },
    {
      label: titles.POWER_BI,
      icon: SettingsIcon,
      routerLink: routes.POWER_BI,
      perform: POWER_BI_VIEW
    },
    {
      label: titles.UNBALANCE_SIDEBAR,
      icon: UnbalanceIcon,
      routerLink: routes.UNBALANCE,
      perform: UNBALANCE_VIEW
    },
    {
      label: titles.FORECAST,
      icon: ForecastIcon,
      routerLink: routes.FORECAST,
      perform: FORECAST_VIEW
    },
    {
      label: titles.MAP,
      icon: MapOutlinedIcon,
      routerLink: routes.MAP,
      perform: MAP_VIEW,
    },
    {
      label: titles.BUSINESS_PLAN,
      icon: BusinessPlanIcon,
      routerLink: routes.BUSINESS_PLAN,
      perform: BUSINESS_PLAN_VIEW
    },
    {
      label: titles.ADMINISTRATION,
      icon: AdministrationPeopleIcon,
      routerLink: routes.ADMINISTRATION,
      perform: ADMINISTRATION_VIEW
    },
    {
      label: titles.RESTRICTIONS_JOURNAL,
      icon: MenuBookIcon,
      routerLink: routes.RESTRICTIONS_JOURNAL,
      perform: RESTRICTIONS_JOURNAL_VIEW
    },
    {
      label: titles.OPERATION_JOURNAL,
      icon: OperationalJournalIcon,
      routerLink: routes.OPERATION_JOURNAL,
      perform: OPERATION_JOURNAL_VIEW
    },
  ];

  return (
    <Drawer
      variant={isMobile ? 'persistent' : 'permanent'}
      anchor={isMobile ? 'top' : 'left'}
      open={open}
      className={clsx(
        classes.drawer,
        open ? classes.drawerOpen : classes.drawerClose,
      )}
      classes={{
        paper: clsx(
          open ? classes.drawerOpen : classes.drawerClose
        ),
      }}
    >
      <List>
        {isLoadedPermission && mainMenu.map(({
          label, icon: Icon, routerLink, perform
        }) => (
          <Can
            perform={perform}
            key={label}
            yes={() => (
              <ListItem
                button
                key={t(label)}
                component={NavLink}
                to={routerLink}
                activeClassName="Mui-selected"
              >
                <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
                <ListItemText primary={t(label)} />
              </ListItem>
            )}
          />

        ))}
      </List>
    </Drawer>
  );
}

export default Sidebar;
