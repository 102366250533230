import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'modules/i18n/en';
import ru from 'modules/i18n/ru';
import uk from 'modules/i18n/uk';

const resources = {
  en,
  uk,
  ru
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'ru',
    debug: true,
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;
