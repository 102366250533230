import API from 'API';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifier';
import * as typeNotifier from 'shared/typeNotifications';
import * as informationMessages from 'constants/infoMessages';

export const START_LOAD_ALL_CHARTS = 'START_LOAD_ALL_CHARTS';
export const SUCCESS_LOAD_ALL_CHARTS = 'LOAD_ALL_CHARTS';
export const ERROR_LOAD_ALL_CHARTS = 'ERROR_LOAD_ALL_CHARTS';
export const DATE_CHANGE_ALL_CHARTS = 'DATE_CHANGE_ALL_CHARTS';

export const loadDataAllCharts = (typeChart, id, date, t) => (dispatch) => {
  dispatch({ type: START_LOAD_ALL_CHARTS });
  API.getDataAllCharts(typeChart, id, date)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_ALL_CHARTS,
        data,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_LOAD_ALL_CHARTS,
      });
      dispatch(enqueueSnackbarAction({
        message: t(`infoMessages:${informationMessages.DATA_LOADING_ERROR}`),
        options: {
          variant: typeNotifier.ERROR,
        },
      }));
      console.error('LOAD_ALL_CHARTS');
    });
};
