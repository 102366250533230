import {
  ADMINISTRATION,
  BUSINESS_PLAN,
  DASHBOARD,
  FORECAST,
  MAP,
  OPERATION_JOURNAL,
  POWER_BI,
  RESTRICTIONS_JOURNAL,
  UNBALANCE_SIDEBAR
} from './titles';

const VIEW = 'view';
const VIEW_CURRENT_PLANT = 'viewCurrentPlant';
const EDIT = 'edit';
const CREATE = 'create';
const RESTORE = 'restore';
const DELETE = 'delete';
const FILE_UPLOAD = 'upload-file';
const FILE_DOWNLOAD = 'download-file';
const CALCULATION = 'calculation';

// Dashboard
export const DASHBOARD_VIEW = `${DASHBOARD}:${VIEW}`;
// PowerBI
export const POWER_BI_VIEW = `${POWER_BI}:${VIEW}`;
// Unbalance
export const UNBALANCE_VIEW = `${UNBALANCE_SIDEBAR}:${VIEW}`;
// Forecast
export const FORECAST_VIEW = `${FORECAST}:${VIEW}`;
// Map
export const MAP_VIEW = `${MAP}:${VIEW}`;
// BusinessPlan
export const BUSINESS_PLAN_VIEW = `${BUSINESS_PLAN}:${VIEW}`;
export const BUSINESS_PLAN_DOWNLOAD = `${BUSINESS_PLAN}:${FILE_DOWNLOAD}`;
export const BUSINESS_PLAN_UPLOAD = `${BUSINESS_PLAN}:${FILE_UPLOAD}`;
// Administration
export const ADMINISTRATION_VIEW = `${ADMINISTRATION}:${VIEW}`;
export const ADMINISTRATION_EDIT = `${ADMINISTRATION}:${EDIT}`;
export const ADMINISTRATION_CREATE = `${ADMINISTRATION}:${CREATE}`;
export const ADMINISTRATION_DELETE = `${ADMINISTRATION}:${DELETE}`;
// RestrictionJournal
export const RESTRICTIONS_JOURNAL_VIEW = `${RESTRICTIONS_JOURNAL}:${VIEW}`;
export const RESTRICTIONS_JOURNAL_EDIT = `${RESTRICTIONS_JOURNAL}:${EDIT}`;
export const RESTRICTIONS_JOURNAL_CREATE = `${RESTRICTIONS_JOURNAL}:${CREATE}`;
export const RESTRICTIONS_JOURNAL_DELETE = `${RESTRICTIONS_JOURNAL}:${DELETE}`;
export const RESTRICTIONS_JOURNAL_CALCULATION = `${RESTRICTIONS_JOURNAL}:${CALCULATION}`;
export const RESTRICTIONS_JOURNAL_VIEW_CURRENT_PLANT = `${RESTRICTIONS_JOURNAL}:${VIEW_CURRENT_PLANT}`;
// OperationJournal
export const OPERATION_JOURNAL_VIEW = `${OPERATION_JOURNAL}:${VIEW}`;
export const OPERATION_JOURNAL_EDIT = `${OPERATION_JOURNAL}:${EDIT}`;
export const OPERATION_JOURNAL_CREATE = `${OPERATION_JOURNAL}:${CREATE}`;
export const OPERATION_JOURNAL_DELETE = `${OPERATION_JOURNAL}:${DELETE}`;
export const OPERATION_JOURNAL_RESTORE = `${OPERATION_JOURNAL}:${RESTORE}`;
export const OPERATION_JOURNAL_VIEW_CURRENT_PLANT = `${OPERATION_JOURNAL}:${VIEW_CURRENT_PLANT}`;
