import {
  START_LOAD_GENERAL_UNBALANCE,
  SUCCESS_LOAD_GENERAL_UNBALANCE,
  ERROR_LOAD_GENERAL_UNBALANCE,
} from 'store/actions/generalUnbalance';

import { isEmpty } from 'lodash';

const initialState = {
  unbalanceProductionWpp: {
    nameChart: '',
    y1Name: '',
    y2Name: '',
    xDateType: 0,
    data: []
  },
  unbalanceDeviationWpp: {
    nameChart: '',
    y1Name: '',
    xDateType: 0,
    data: []
  },
  unbalanceProductionSpp: {
    nameChart: '',
    y1Name: '',
    y2Name: '',
    xDateType: 0,
    data: []
  },
  unbalanceDeviationSpp: {
    nameChart: '',
    y1Name: '',
    xDateType: 0,
    data: []
  },
  isLoading: false,
  isLoaded: false,
  isError: false,
};
const generalUnbalance = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD_GENERAL_UNBALANCE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS_LOAD_GENERAL_UNBALANCE: {
      const { dataWpp, dataSpp } = action;
      return {
        ...state,
        unbalanceProductionWpp: dataWpp.unbalanceProduction,
        unbalanceDeviationWpp: dataWpp.unbalanceDeviation,
        unbalanceProductionSpp: dataSpp.unbalanceProduction,
        unbalanceDeviationSpp: dataSpp.unbalanceDeviation,
        isLoading: false,
        isLoaded: !(isEmpty(dataWpp) || isEmpty(dataSpp)),
      };
    }
    case ERROR_LOAD_GENERAL_UNBALANCE: {
      return {
        ...state,
        isError: true,
      };
    }
    default:
      return state;
  }
};

export default generalUnbalance;
