import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './styles';

const SelectControl = ({
  selectedItem, items, title, onHandleChange, variant,
  fullWidth, keyId, keyValue
}) => {
  const classes = useStyles();
  return (
    <FormControl
      size="small"
      variant={variant}
      className={clsx(classes.formControl, {
        [classes.fullWidth]: fullWidth
      })}
    >
      <InputLabel id="forecast-select-outlined-label">{title}</InputLabel>
      <Select
        labelId="forecast-select-outlined-label"
        id="forecast-select"
        value={selectedItem}
        onChange={onHandleChange}
        label={title}
      >
        {items.map((item) => (<MenuItem key={item[keyId]} value={item[keyId]}>{item[keyValue]}</MenuItem>))}
      </Select>
    </FormControl>
  );
};

SelectControl.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    text: PropTypes.string,
  })),
  selectedItem: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onHandleChange: PropTypes.func,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  keyId: PropTypes.string,
  keyValue: PropTypes.string,
};

SelectControl.defaultProps = {
  title: '',
  items: [],
  selectedItem: '',
  onHandleChange: () => {},
  variant: 'outlined',
  fullWidth: false,
  keyId: 'value',
  keyValue: 'text',
};
export default memo(SelectControl);
