import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import users from './users';
import account from './account';
import notifier from './notifier';
import charts from './charts';
import chartsLiveData from './chartsLiveData';
import stations from './stations';
import unbalance from './unbalance';
import generalUnbalance from './generalUnbalance';
import unbalanceDeviations from './unbalanceDeviations';
import reportFiles from './reportFiles';
import businessPlan from './businessPlan';
import businessPlanDetails from './businessPlanDetails';
import generalStatistic from './generalStatistic';
import forecast from './forecast';
import restrictionsJournal from './restrictionsJournal';
import sidebar from './sidebar';
import map from './map';
import operationalJournal from './operationalJournal';
import administration from './administration';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  users,
  account,
  notifier,
  charts,
  chartsLiveData,
  stations,
  unbalance,
  generalUnbalance,
  unbalanceDeviations,
  reportFiles,
  businessPlan,
  businessPlanDetails,
  generalStatistic,
  forecast,
  restrictionsJournal,
  sidebar,
  map,
  operationalJournal,
  administration,
});

export default rootReducer;
