import {
  START_LOAD_FORECAST_LIST,
  SUCCESS_LOAD_FORECAST_LIST,
  START_LOAD_FORECAST_METRICS,
  SUCCESS_LOAD_FORECAST_METRICS,
  DATE_CHANGE_FORECAST,
  CHANGE_CHECKBOX,
  CHANGE_HORIZON_FORECAST,
  ERROR_LOAD_FORECAST,
} from 'store/actions/forecast';
import { dateFormatISO } from 'shared/dateFormat';

import {
  isEmpty, map
} from 'lodash';
import {
  endOfISOWeek,
  startOfISOWeek,
  subWeeks,
} from 'date-fns';

const currentDate = new Date();

const defaultDate = {
  from: dateFormatISO(subWeeks(startOfISOWeek(currentDate), 1)),
  to: dateFormatISO(subWeeks(endOfISOWeek(currentDate), 1))
};
const defaultHorizonForecast = 2;

const initialState = {
  forecast: {},
  plantForecastExecutionProvider: {},
  plantForecastUnbalanceInMwByProvider: {},
  outOfThresholdForecastInMwByProvider: {},
  bias: {},
  nmae: {},
  nrmse: {},
  list: [],
  date: defaultDate,
  horizonForecast: defaultHorizonForecast,
  isLoadingList: false,
  isLoadedList: false,
  isErrorList: false,
  isLoadingMetrics: false,
  isLoadedMetrics: false,
  isErrorMetrics: false,
};
const forecast = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD_FORECAST_LIST: {
      return {
        ...state,
        isLoadingList: true,
      };
    }
    case SUCCESS_LOAD_FORECAST_LIST: {
      const { data } = action;
      return {
        ...state,
        list: data,
        isLoadingList: false,
        isLoadedList: !isEmpty(data),
      };
    }
    case START_LOAD_FORECAST_METRICS: {
      return {
        ...state,
        isLoadingMetrics: true,
      };
    }
    case SUCCESS_LOAD_FORECAST_METRICS: {
      const {
        data: {
          forecast: { nameChart, xDateType, data: dataMetrics },
          plantForecastExecutionProvider,
          plantForecastUnbalanceInMwByProvider,
          outOfThresholdForecastInMwByProvider,
          bias,
          nmae,
          nrmse
        }
      } = action;

      return {
        ...state,
        forecast: {
          nameChart,
          xDateType,
          data: map(dataMetrics, (item) => {
            const metricDots = item.data.reduce((result, currentItem) => {
              const keys = Object.keys(currentItem);
              // eslint-disable-next-line no-param-reassign
              result[`value_${currentItem[keys[0]]}`] = currentItem[keys[1]];
              return result;
            }, {});
            return {
              x: item.x,
              ...metricDots,
            };
          })
        },
        plantForecastExecutionProvider,
        plantForecastUnbalanceInMwByProvider,
        outOfThresholdForecastInMwByProvider,
        bias,
        nmae,
        nrmse,
        isLoadingMetrics: false,
        isLoadedMetrics: !isEmpty(dataMetrics),
      };
    }
    case CHANGE_CHECKBOX: {
      const { id } = action;
      return {
        ...state,
        list: map(state.list, (item) => {
          if (item.id === id) {
            return {
              ...item,
              isDefault: !item.isDefault
            };
          }
          return item;
        })
      };
    }
    case DATE_CHANGE_FORECAST: {
      return {
        ...state,
        date: { ...state.date, ...action.date }
      };
    }
    case CHANGE_HORIZON_FORECAST: {
      return {
        ...state,
        horizonForecast: action.horizonForecast
      };
    }
    case ERROR_LOAD_FORECAST: {
      return {
        ...state,
        isErrorMetrics: true,
        isErrorList: true,
      };
    }
    default:
      return state;
  }
};

export default forecast;
