import React from 'react';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Notifier from './Notifier';
import useStyles from './styles';

const NotifierProvider = ({ children }) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      className={classes.root}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
      iconVariant={{
        success: <CheckCircleOutlineIcon className="snackbarIcons" />,
        info: <InfoOutlinedIcon className="snackbarIcons" />,
        warning: <ReportProblemOutlinedIcon className="snackbarIcons" />,
        error: <CancelOutlinedIcon className="snackbarIcons" />,
      }}
    >
      <Notifier />
      {children}
    </SnackbarProvider>
  );
};

NotifierProvider.propTypes = {
  children: PropTypes.node,
};

NotifierProvider.defaultProps = {
  children: null,
};
export default NotifierProvider;
