import routeList from 'modules/Routes/routeConfig';
import * as routes from 'shared/routes';
import * as typePowerPlant from 'shared/typePowerPlant';

const setNameCrumbs = ({
  path, params, spp, wpp
}) => {
  const station = path.includes(typePowerPlant.WPP) ? wpp : spp;
  const { name } = station.find(({ id }) => (id === +params[routes.ID_STATION])) || {};
  return name;
};

const breadcrumbs = (params, path, spp, wpp) => {
  const paramsKeys = Object.keys(params);
  const crumbs = routeList
    .filter(({ path: routeLink }) => path.includes(routeLink))
    .map(({ path: link, name: nameCrumbs, ...rest }) => ({
      path: paramsKeys.length
        ? paramsKeys.reduce(
          (accumulatorPath, param) => accumulatorPath.replace(`:${param}`, params[param]),
          link
        )
        : link,
      name: (nameCrumbs === routes.ID_STATION && wpp.length > 0) ? setNameCrumbs({
        path, params, spp, wpp
      }) : nameCrumbs,
      ...rest,
    }));
  return crumbs;
};

export default breadcrumbs;
