export const DASHBOARD = '/dashboard';
export const CHARTS = '/charts';
export const USERS = '/users';
export const ABOUT = '/about';
export const WPP = '/wpp';
export const SPP = '/spp';
export const POWER_BI = '/powerBI';
export const MAP = '/map';
export const UNBALANCE = '/unbalance';
export const BUSINESS_PLAN = '/businessPlan';
export const GENERAL_STATISTIC = '/generalStatistic';
export const FORECAST = '/forecast';
export const CREATE = '/create';
export const VIEW = '/view';
export const EDIT = '/edit';
export const NO_ACCESS = '/no-access';
export const RESTRICTIONS_JOURNAL = '/restrictionsJournal';
export const RESTRICTIONS_JOURNAL_CREATE = RESTRICTIONS_JOURNAL + CREATE;
export const RESTRICTIONS_JOURNAL_VIEW = RESTRICTIONS_JOURNAL + VIEW;
export const RESTRICTIONS_JOURNAL_EDIT = RESTRICTIONS_JOURNAL + EDIT;
export const PARAMS = '/:';
export const ID = 'id';
export const RESTRICTIONS_JOURNAL_VIEW_DETAILS = RESTRICTIONS_JOURNAL + VIEW + PARAMS + ID;
export const RESTRICTIONS_JOURNAL_VIEW_EDIT = RESTRICTIONS_JOURNAL + EDIT + PARAMS + ID;

export const OPERATION_JOURNAL = '/OperationalJournal';
export const OPERATION_JOURNAL_CREATE = OPERATION_JOURNAL + CREATE;
export const OPERATION_JOURNAL_VIEW = OPERATION_JOURNAL + VIEW;
export const OPERATION_JOURNAL_EDIT = OPERATION_JOURNAL + EDIT;

export const ADMINISTRATION = '/administration';
export const ADMINISTRATION_CREATE = ADMINISTRATION + CREATE;
export const ADMINISTRATION_VIEW = ADMINISTRATION + VIEW;
export const ADMINISTRATION_EDIT = ADMINISTRATION + EDIT;

export const ID_STATION = 'IdStation';
export const QUERY_ID_STATION = '?IdStation';
export const CHARTS_WPP = DASHBOARD + CHARTS + WPP;
export const CHARTS_SPP = DASHBOARD + CHARTS + SPP;
export const CHARTS_GENERAL_STATISTIC = DASHBOARD + CHARTS + GENERAL_STATISTIC;
export const CHARTS_WPP_QUERY_ID_STATION = DASHBOARD + CHARTS + WPP + QUERY_ID_STATION;
export const CHARTS_SPP_QUERY_ID_STATION = DASHBOARD + CHARTS + SPP + QUERY_ID_STATION;
export const POWER_BI_WPP = POWER_BI + WPP;
export const POWER_BI_SPP = POWER_BI + SPP;
export const UNBALANCE_WPP = UNBALANCE + WPP;
export const UNBALANCE_SPP = UNBALANCE + SPP;
export const UNBALANCE_WPP_QUERY_ID_STATION = UNBALANCE + WPP + QUERY_ID_STATION;
export const UNBALANCE_SPP_QUERY_ID_STATION = UNBALANCE + SPP + QUERY_ID_STATION;
export const BUSINESS_PLAN_DETAILS = BUSINESS_PLAN + PARAMS + ID;
export const FORECAST_WPP = FORECAST + WPP;
export const FORECAST_SPP = FORECAST + SPP;
export const FORECAST_WPP_QUERY_ID_STATION = FORECAST_WPP + QUERY_ID_STATION;
export const FORECAST_SPP_QUERY_ID_STATION = FORECAST_SPP + QUERY_ID_STATION;
