import {
  START_LOAD_CHARTS_LIVE_DATA,
  SUCCESS_LOAD_CHARTS_LIVE_DATA,
  ERROR_LOAD_CHARTS_LIVE_DATA,
} from 'store/actions/chartsLiveData';

import { isEmpty } from 'lodash';

const initialState = {
  nameValues: [],
  windSpeedAndPower: {
    nameChart: '',
    y1Name: '',
    y2Name: '',
    y1AvgName: '',
    y2AvgName: '',
    y1Avg: '',
    y2Avg: '',
    data: []
  },
  insolationAndOutput: {
    nameChart: '',
    y1Name: '',
    y2Name: '',
    y1AvgName: '',
    y2AvgName: '',
    y1Avg: '',
    y2Avg: '',
    data: [],
    y1Unit: '',
    y2Unit: '',
  },
  isLoading: false,
  isLoaded: false,
  isError: false,
};
const chartsLiveData = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD_CHARTS_LIVE_DATA: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS_LOAD_CHARTS_LIVE_DATA: {
      const { data } = action;
      return {
        ...state,
        ...data,
        isLoading: false,
        isLoaded: !isEmpty(data),
      };
    }
    case ERROR_LOAD_CHARTS_LIVE_DATA: {
      return {
        ...state,
        isError: true,
      };
    }
    default:
      return state;
  }
};

export default chartsLiveData;
