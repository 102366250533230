import API from 'API';

export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_HANDLE_LOGOUT = 'SET_HANDLE_LOGOUT';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SUCCESS_LOAD_PERMISSION = 'SUCCESS_LOAD_PERMISSION';

export const setUserData = (data) => {
  const { userName, name } = data;
  return {
    type: SET_USER_DATA,
    data: { userName, name },
  };
};

export const setLogout = (handleLogout) => {
  if (typeof handleLogout !== 'function') return;

  return {
    type: SET_HANDLE_LOGOUT,
    data: handleLogout,
  };
};

export const getUserPermission = () => (dispatch) => {
  API.getAuth()
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_PERMISSION,
        data,
      });
    })
    .catch(() => {
      console.error('load_Permission_Error');
    });
};
