import {
  BUSINESS_PLAN_DOWNLOAD,
  BUSINESS_PLAN_UPLOAD,
  BUSINESS_PLAN_VIEW,
  DASHBOARD_VIEW,
  FORECAST_VIEW,
  MAP_VIEW,
  OPERATION_JOURNAL_VIEW,
  OPERATION_JOURNAL_EDIT,
  OPERATION_JOURNAL_CREATE,
  OPERATION_JOURNAL_DELETE,
  OPERATION_JOURNAL_RESTORE,
  OPERATION_JOURNAL_VIEW_CURRENT_PLANT,
  POWER_BI_VIEW,
  RESTRICTIONS_JOURNAL_VIEW,
  RESTRICTIONS_JOURNAL_EDIT,
  RESTRICTIONS_JOURNAL_CREATE,
  RESTRICTIONS_JOURNAL_DELETE,
  RESTRICTIONS_JOURNAL_CALCULATION,
  RESTRICTIONS_JOURNAL_VIEW_CURRENT_PLANT,
  UNBALANCE_VIEW,
  ADMINISTRATION_VIEW,
  ADMINISTRATION_EDIT,
  ADMINISTRATION_CREATE,
  ADMINISTRATION_DELETE
} from 'constants/permissions';
import roles from 'constants/roles';

const roleTypes = {
  APP_CONTENT: 'app',
};

const rules = {
  [roles.ADMIN]: {
    static: [
      DASHBOARD_VIEW,
      POWER_BI_VIEW,
      UNBALANCE_VIEW,
      FORECAST_VIEW,
      MAP_VIEW,
      BUSINESS_PLAN_VIEW,
      BUSINESS_PLAN_DOWNLOAD,
      BUSINESS_PLAN_UPLOAD,
      ADMINISTRATION_VIEW,
      ADMINISTRATION_EDIT,
      ADMINISTRATION_CREATE,
      ADMINISTRATION_DELETE,
      RESTRICTIONS_JOURNAL_VIEW,
      RESTRICTIONS_JOURNAL_EDIT,
      RESTRICTIONS_JOURNAL_CREATE,
      RESTRICTIONS_JOURNAL_DELETE,
      RESTRICTIONS_JOURNAL_CALCULATION,
      RESTRICTIONS_JOURNAL_VIEW_CURRENT_PLANT,
      OPERATION_JOURNAL_VIEW,
      OPERATION_JOURNAL_EDIT,
      OPERATION_JOURNAL_CREATE,
      OPERATION_JOURNAL_DELETE,
      OPERATION_JOURNAL_RESTORE,
      OPERATION_JOURNAL_VIEW_CURRENT_PLANT
    ],
  },
  [roles.SHIFT_SUPERVISOR]: {
    static: [
      DASHBOARD_VIEW,
      POWER_BI_VIEW,
      UNBALANCE_VIEW,
      FORECAST_VIEW,
      MAP_VIEW,
      BUSINESS_PLAN_VIEW,
      BUSINESS_PLAN_DOWNLOAD,
      RESTRICTIONS_JOURNAL_VIEW,
      RESTRICTIONS_JOURNAL_CALCULATION,
      OPERATION_JOURNAL_VIEW,
      OPERATION_JOURNAL_EDIT,
      OPERATION_JOURNAL_CREATE,
      OPERATION_JOURNAL_DELETE,
      OPERATION_JOURNAL_RESTORE,
      OPERATION_JOURNAL_VIEW_CURRENT_PLANT
    ],
  },
  [roles.POWER_ENGINEER]: {
    static: [
      DASHBOARD_VIEW,
      POWER_BI_VIEW,
      UNBALANCE_VIEW,
      FORECAST_VIEW,
      MAP_VIEW,
      BUSINESS_PLAN_VIEW,
      BUSINESS_PLAN_DOWNLOAD,
      RESTRICTIONS_JOURNAL_VIEW,
      RESTRICTIONS_JOURNAL_CALCULATION,
      OPERATION_JOURNAL_VIEW,
      OPERATION_JOURNAL_VIEW_CURRENT_PLANT
    ],
  },
  [roles.EXPERT]: {
    static: [
      DASHBOARD_VIEW,
      POWER_BI_VIEW,
      UNBALANCE_VIEW,
      FORECAST_VIEW,
      MAP_VIEW,
      BUSINESS_PLAN_VIEW,
      BUSINESS_PLAN_DOWNLOAD,
      RESTRICTIONS_JOURNAL_VIEW,
      RESTRICTIONS_JOURNAL_CALCULATION,
      OPERATION_JOURNAL_VIEW
    ],
  },
  [roles.DISPATCHER]: {
    static: [
      DASHBOARD_VIEW,
      POWER_BI_VIEW,
      UNBALANCE_VIEW,
      FORECAST_VIEW,
      MAP_VIEW,
      RESTRICTIONS_JOURNAL_VIEW,
      RESTRICTIONS_JOURNAL_CREATE,
      RESTRICTIONS_JOURNAL_EDIT,
      RESTRICTIONS_JOURNAL_DELETE,
      RESTRICTIONS_JOURNAL_CALCULATION,
      RESTRICTIONS_JOURNAL_VIEW_CURRENT_PLANT,
    ],
  },
  [roles.USER]: {
    static: [
      DASHBOARD_VIEW,
      POWER_BI_VIEW,
      UNBALANCE_VIEW,
      FORECAST_VIEW,
      MAP_VIEW,
    ],
  },
};

export { roleTypes };
export default rules;
