import { TOGGLE_DRAWER } from '../actions/sidebar';

const initialState = {
  open: false,
};

const sidebar = (state = initialState, { type, value }) => {
  switch (type) {
    case TOGGLE_DRAWER: {
      return {
        ...state,
        open: value
      };
    }
    default:
      return state;
  }
};

export default sidebar;
