import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setUserData, setLogout } from 'store/actions/account';
import MsalAuth, { ACCESS_TOKEN_NAME } from 'utils/MsalAuth';
import Loader from 'pages/PageLoader';

const msal = new MsalAuth();

const AuthContainer = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    const setUserTokenAndRenderApp = ({ accessToken }) => {
      localStorage.setItem(ACCESS_TOKEN_NAME, accessToken);
      setLoggedIn(true);
    };

    if (msal.getAccount()) {
      msal
        .getTokenSilent()
        .then(setUserTokenAndRenderApp)
        .catch(({ errorCode = null }) => {
          console.error('getAccessToken Error');
          if (msal.requiresInteraction(errorCode)) {
            msal.acquireTokenRedirect();
            return;
          }
          if (msal.isUserLoginError(errorCode)) {
            msal.redirectToLogin();
          }
        });
    } else {
      msal.redirectToLogin();
    }
  }, []);
  return (
    <>
      {loggedIn && children}
      {!loggedIn && <Loader />}
    </>
  );
};

const RootAuth = ({ children }) => {
  const dispatch = useDispatch();

  const handleSetUserData = () => {
    msal.getAccount().then((result) => {
      dispatch(setUserData(result));
    });
  };
  const handleSetLogout = () => dispatch(setLogout(msal.logout));
  useEffect(() => {
    handleSetUserData();
    handleSetLogout();
  }, [handleSetLogout]);

  return <AuthContainer>{children}</AuthContainer>;
};

RootAuth.propTypes = {
  children: PropTypes.node.isRequired,
};
AuthContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export { msal };
export default RootAuth;
