const en = {
  others: {
    generalStatistics: 'General Statistics',
    wpp: 'Wind power plants (WPP)',
    spp: 'Solar power plants (SPP)',
    allWpp: 'All WPP',
    allSpp: 'All SPP',
    WppSpp: 'WPP-SPP',
    wpp_botievo: 'Botievskaya WPP',
    wpp_orlovsk: 'Orlovskaya WPP',
    wpp_promorsk1: 'Primorskaya WPP-1',
    wpp_primorsk2: 'Primorskaya WPP-2',
    spp_nikopol: 'Nikopolskaya SPP',
    spp_pokrovsk: 'Pokrovskaya SPP',
    spp_trifanovsk: 'Trifanovskaya SPP',
    windPowerPlants: 'Wind power plants (WPP)',
    solarPowerPlants: 'Solar power plants (SPP) ',
    dashboard: 'Dashboard',
    powerBI: 'Power BI',
    unbalance: 'Unbalance',
    forecasts: 'Forecasts',
    map: 'Map',
    businessPlan: 'Business plan',
    users: 'Users',
    restrictionsJournal: 'Restrictions Journal',
    restrictionsJournalCreate: 'Creation application',
    restrictionsJournalView: 'View application',
    restrictionsJournalEdit: 'Editing an application',
    forecastAndActualProduction: 'Forecast and actual production (WPP, SPP)',
    deviation: 'Deviation',
    totalVariation: 'Total variation',
    keyMetrics: 'Key Metrics',
    liveData: 'Live data',
    budgetVsFact: 'Budget vs fact',
    generalUnbalanceTooltip: 'Positive and negative unbalances, also their sum',
    unbalanceOutOfRangeTooltip: 'Positive and negative unbalances that go beyond the permissible threshold, also their sum',
    percentageOfPredictionsTooltip: 'Percentage of predictions that came true within the acceptable deviation threshold ',
    biasTooltip: 'Discrepancies between the forecast data and the actual (factual) data ',
    nmaeTooltip: 'Normalized mean absolute error',
    nrmseTooltip: 'Normalized mean square error',
    bPSuccess: 'Business plan uploaded successfully',
    bpName: 'BP Name',
    bpYear: 'Year',
    bpAuthor: 'Author',
    bpTemplate: 'Business plan template',
    currentCapacity: 'Current capacity',
    projectCapacity: 'Project capacity',
    nestedListItems: 'Nested List Items',
    variationOfForecast: 'Variation of forecast from actual production',
    forecast: 'Forecast',
    actual: 'Actual',
    station: 'Station',
    namePlan: 'Business plan of generation WPP-SPP',
    Production: 'Production',
    EnergyAvailability: 'Energy Availability',
    WindResource: 'Wind Resource',
    Insolation: 'Insolation',
    forecastHorizon: 'Forecast horizon',
    Average: 'Average',
    'Guaranteed Buyer': 'Guaranteed Buyer',
    Model: 'Model',
    Fact: 'Fact',
    startDateRDC: '',
    powerCommandRestriction: '',
    powerBefore: '',
    endDateRDC: '',
    duration: '',
    powerAfter: '',
    lossesSCADA: '',
    lossesCalculatedOrEtalon: '',
    positiveImbalance: 'Positive imbalance',
    negativeImbalance: 'Negative imbalance',
    relativeUnbalanceIndex: 'Relative unbalance index',
    openPowerBI: 'Open PowerBI in a new tab',
    OperationalJournal: 'Operational Journal',
    OperationalJournalCreate: 'Create a record',
    OperationalJournalView: 'View entry',
    OperationalJournalEdit: 'Edit entry',
    OperationalJournalCreateRecord: 'Create an independent record',
    Administration: 'Administration',
    AdministrationCreate: 'Add new user',
    AdministrationEdit: 'Editing user data',
    AdministrationView: 'View user data',
    status: 'Status',
    includedInClosedChain: 'Included in a closed chain',
    deletedStatus: 'Crossed out',
    event: 'Event',
    causeCategory: 'Сase Category',
    equipment: 'Equipment',
    timestamp: 'Time',
    peopleId: 'User ID',
    email: 'Email',
    name: 'Name',
    surname: 'Surname',
    role: 'Role',
    location: 'Location',
    admin: 'Admin',
    shiftSupervisor: 'Shift Supervisor',
    powerEngineer: 'Power Engineer',
    expert: 'Expert',
    dispatcher: 'Dispatcher',
    user: 'User',
  },
  infoMessages: {
    noData: 'No data',
    selectedPeriodNotMultipleCalendarWeek: 'You have selected a period that is not a multiple to a calendar week. Indicators NMAE, NMRSE and bias will be displayed incorrectly',
    loadingPleaseWait: 'Loading, please wait',
    notAccess: 'This page does not match the access rights',
    connectSupport: 'Contact support for access control and data display',
    dataLoadingError: 'Data loading error',
    errorLoadingFile: 'Error loading file',
    errorLoadingDateEmptyData: 'Error loading dates for which data is present',
    errorLoadingListForecasters: 'Error loading the list of forecasters',
    updatingData: 'Updating data',
    fileError: 'File error',
    uploadFileVerification: 'Upload file for verification',
    overwriteData: 'Data for the download period already exist in the database. Are you sure you want to overwrite the data?',
    generalImbalance: 'General imbalance',
    totalDeviation: 'Total deviation',
    title404: 'The page you are looking for does not exist or has been deleted',
    desc404_1: 'Try to start your journey with',
    desc404_2: 'home page',
    dataUpdateSuccess: 'Changes added successfully',
    deleteData: 'Заявка {{id}} успешно удалена',
    dataUpdateError: 'Изменения не добавлены Обратитесь в службу поддержки для решения проблемы',
    dataCreateSuccess: 'Entry {{id}} created successfully',
  },
  btn: {
    ok: 'Ok',
    save: 'Save',
    cancel: 'Cancel',
    downloadTemplate: 'Download template',
    uploadBP: 'Upload BP',
    downloadBP: 'Download BP',
    delete: 'Delete',
    edit: 'Edit',
    not: 'Not',
    yes: 'Yes',
    crossOut: 'Cross Out',
  },
  unit: {
    mw: 'MW',
    kWh: 'kWh',
    wm2: 'W/m²',
    mc: 'm/s',
    mWH: 'MW•H',
    kilowatt: 'kW',
    kilowattMeter2: 'kW/m²',
    percentageFell: 'Percentage of predictions that fell within acceptable limits',
    hour: 'hour',
    hours: 'hours',
  },
  date: {
    today: 'Today',
    lastWeek: 'Last week',
    currentWeek: 'This week',
    prevWeek: 'Previous week',
    currentMonth: 'Current month',
    PrevMonth: 'Previous month',
    lastYear: 'Last year',
    currentYear: 'Current year',
    from: 'From',
    to: 'To',
    titleDRP: 'Select a period',
    hour: 'hour',
    hours: 'hours',
    calendar: 'Calendar',
    year: 'Year',
    weekToDate: 'Week to date',
    monthToDate: 'Month to date',
    yearToDate: 'Year to date',
  }
};

export default en;
