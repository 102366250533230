import API from 'API';
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions/notifier';
import * as typeNotifier from 'shared/typeNotifications';
import * as informationMessages from 'constants/infoMessages';

export const START_LOAD_RESTRICTIONS_JOURNAL = 'START_LOAD_RESTRICTIONS_JOURNAL';
export const SUCCESS_LOAD_RESTRICTIONS_JOURNAL = 'SUCCESS_LOAD_RESTRICTIONS_JOURNAL';
export const ERROR_LOAD_RESTRICTIONS_JOURNAL = 'ERROR_LOAD_RESTRICTIONS_JOURNAL';
export const DATE_CHANGE_FILTER = 'DATE_CHANGE_FILTER';
export const MULTI_SELECT_CHANGE_FILTER = 'MULTI_SELECT_CHANGE_FILTER';
export const RADIO_SELECT_CHANGE_FILTER = 'RADIO_SELECT_CHANGE_FILTER';
export const APPLY_FILTER = 'APPLY_FILTER';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const SET_PAGINATION = 'SET_PAGINATION';

export const CLEAR_RESTRICTIONS_JOURNAL_ITEM = 'CLEAR_RESTRICTIONS_JOURNAL_ITEM';

export const START_LOAD_RESTRICTIONS_JOURNAL_ITEM = 'START_LOAD_RESTRICTIONS_JOURNAL_ITEM';
export const SUCCESS_LOAD_RESTRICTIONS_JOURNAL_ITEM = 'SUCCESS_LOAD_RESTRICTIONS_JOURNAL_ITEM';
export const ERROR_LOAD_RESTRICTIONS_JOURNAL_ITEM = 'ERROR_LOAD_RESTRICTIONS_JOURNAL_ITEM';

export const START_CREATE_RESTRICTIONS_JOURNAL_ITEM = 'START_CREATE_RESTRICTIONS_JOURNAL_ITEM';
export const SUCCESS_CREATE_RESTRICTIONS_JOURNAL_ITEM = 'SUCCESS_CREATE_RESTRICTIONS_JOURNAL_ITEM';
export const ERROR_CREATE_RESTRICTIONS_JOURNAL_ITEM = 'ERROR_CREATE_RESTRICTIONS_JOURNAL_ITEM';

export const START_UPDATE_RESTRICTIONS_JOURNAL_ITEM = 'START_UPDATE_RESTRICTIONS_JOURNAL_ITEM';
export const SUCCESS_UPDATE_RESTRICTIONS_JOURNAL_ITEM = 'SUCCESS_UPDATE_RESTRICTIONS_JOURNAL_ITEM';
export const ERROR_UPDATE_RESTRICTIONS_JOURNAL_ITEM = 'ERROR_UPDATE_RESTRICTIONS_JOURNAL_ITEM';

export const START_DELETE_RESTRICTIONS_JOURNAL_ITEM = 'START_DELETE_RESTRICTIONS_JOURNAL_ITEM';
export const SUCCESS_DELETE_RESTRICTIONS_JOURNAL_ITEM = 'SUCCESS_DELETE_RESTRICTIONS_JOURNAL_ITEM';
export const ERROR_DELETE_RESTRICTIONS_JOURNAL_ITEM = 'ERROR_DELETE_RESTRICTIONS_JOURNAL_ITEM';

export const START_LOAD_LOSSES_RESTRICTIONS_JOURNAL = 'START_LOAD_LOSSES_RESTRICTIONS_JOURNAL';
export const SUCCESS_LOAD_LOSSES_RESTRICTIONS_JOURNAL = 'SUCCESS_LOAD_LOSSES_RESTRICTIONS_JOURNAL';
export const ERROR_LOAD_LOSSES_RESTRICTIONS_JOURNAL = 'ERROR_LOAD_LOSSES_RESTRICTIONS_JOURNAL';

export const getRestrictionsJournal = (t, dto) => (dispatch) => {
  dispatch({ type: START_LOAD_RESTRICTIONS_JOURNAL });
  API.getRestrictionsJournalTable(dto)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_RESTRICTIONS_JOURNAL,
        data,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_LOAD_RESTRICTIONS_JOURNAL,
      });
      dispatch(enqueueSnackbarAction({
        message: t(`infoMessages:${informationMessages.DATA_LOADING_ERROR}`),
        options: {
          variant: typeNotifier.ERROR,
        },
      }));
      console.error('RESTRICTIONS_JOURNAL_TABLE');
    });
};

export const getRestrictionsJournalItemById = (id) => (dispatch) => {
  dispatch({ type: START_LOAD_RESTRICTIONS_JOURNAL_ITEM });
  API.getRestrictionsJournalItemById(id)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_RESTRICTIONS_JOURNAL_ITEM,
        data,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_LOAD_RESTRICTIONS_JOURNAL_ITEM,
      });
    });
};

export const clearRestrictionsJournalItem = () => ({ type: CLEAR_RESTRICTIONS_JOURNAL_ITEM });

export const createRestrictionsJournalItem = (param) => (dispatch) => {
  dispatch({ type: START_CREATE_RESTRICTIONS_JOURNAL_ITEM });
  API.createRestrictionsJournalItem(param)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_CREATE_RESTRICTIONS_JOURNAL_ITEM,
        data,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_CREATE_RESTRICTIONS_JOURNAL_ITEM,
      });
    });
};
export const updateRestrictionsJournalItem = (param, t) => (dispatch) => {
  dispatch({ type: START_UPDATE_RESTRICTIONS_JOURNAL_ITEM });
  API.updateRestrictionsJournalItem(param)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_UPDATE_RESTRICTIONS_JOURNAL_ITEM,
        data,
      });
      dispatch(enqueueSnackbarAction({
        message: t(`infoMessages:${informationMessages.DATA_UPDATE_SUCCESS}`),
        options: {
          variant: typeNotifier.SUCCESS,
        },
      }));
    })
    .catch(() => {
      dispatch({
        type: ERROR_UPDATE_RESTRICTIONS_JOURNAL_ITEM,
      });
      dispatch(enqueueSnackbarAction({
        message: t(`infoMessages:${informationMessages.DATA_UPDATE_ERROR}`),
        options: {
          variant: typeNotifier.ERROR,
        },
      }));
    });
};

export const deleteRestrictionsJournalItem = (id, message) => (dispatch) => {
  dispatch({ type: START_DELETE_RESTRICTIONS_JOURNAL_ITEM });
  API.deleteRestrictionsJournalItem(id)
    .then(() => {
      dispatch({
        type: SUCCESS_DELETE_RESTRICTIONS_JOURNAL_ITEM
      });
      dispatch(enqueueSnackbarAction({
        message,
        options: {
          variant: typeNotifier.SUCCESS,
        },
      }));
    })
    .catch(() => {
      dispatch({
        type: ERROR_DELETE_RESTRICTIONS_JOURNAL_ITEM,
      });
    });
};

export const getLossesSum = (dto) => (dispatch) => {
  dispatch({ type: START_LOAD_LOSSES_RESTRICTIONS_JOURNAL });
  API.getLossesSum(dto)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_LOAD_LOSSES_RESTRICTIONS_JOURNAL,
        data,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR_LOAD_LOSSES_RESTRICTIONS_JOURNAL,
      });
      console.error('RESTRICTIONS_JOURNAL_LOSSES_SUM');
    });
};
