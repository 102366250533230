export const ALL_WPP = 'allWpp';
export const ALL_SPP = 'allSpp';
export const DASHBOARD = 'dashboard';
export const WPP = 'wpp';
export const SPP = 'spp';
export const MAP = 'map';
export const UNBALANCE = 'forecastAndActualProduction';
export const UNBALANCE_SIDEBAR = 'unbalance';
export const POWER_BI = 'powerBI';
export const BUSINESS_PLAN = 'businessPlan';
export const GENERAL_STATISTIC = 'generalStatistics';
export const FORECAST = 'forecasts';
export const WppSpp = 'WppSpp';
export const RESTRICTIONS_JOURNAL = 'restrictionsJournal';
export const RESTRICTIONS_JOURNAL_CREATE = 'restrictionsJournalCreate';
export const RESTRICTIONS_JOURNAL_VIEW = 'restrictionsJournalView';
export const RESTRICTIONS_JOURNAL_EDIT = 'restrictionsJournalEdit';
export const OPERATION_JOURNAL = 'OperationalJournal';
export const OPERATION_JOURNAL_CREATE = 'OperationalJournalCreate';
export const OPERATION_JOURNAL_EDIT = 'OperationalJournalEdit';
export const OPERATION_JOURNAL_VIEW = 'OperationalJournalView';
export const OPERATION_JOURNAL_CREATE_RECORD = 'OperationalJournalCreateRecord';
export const ADMINISTRATION = 'Administration';
export const ADMINISTRATION_CREATE = 'AdministrationCreate';
export const ADMINISTRATION_VIEW = 'AdministrationView';
export const ADMINISTRATION_EDIT = 'AdministrationEdit';
