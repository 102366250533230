const roles = {
  ADMIN: 'admin',
  ADMIN_ID: 1,
  SHIFT_SUPERVISOR: 'shiftSupervisor',
  SHIFT_SUPERVISOR_ID: 2,
  POWER_ENGINEER: 'powerEngineer',
  POWER_ENGINEER_ID: 3,
  EXPERT: 'expert',
  EXPERT_ID: 4,
  DISPATCHER: 'dispatcher',
  DISPATCHER_ID: 5,
  USER: 'user',
  USER_ID: 6,
};

export default roles;
