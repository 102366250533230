import { isEmpty } from 'lodash';
import * as typeAlert from 'shared/typeAlert';

import {
  START_LOAD_REPORT_FILE,
  SUCCESS_LOAD_REPORT_FILE,
  ERROR_LOAD_REPORT_FILE,
  SUCCESS_LOAD_UPDATE_FILE
} from 'store/actions/reportFiles';

const initialState = {
  isLoading: false,
  isLoaded: false,
  isError: false,
  data: {}
};
const reportFiles = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD_REPORT_FILE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS_LOAD_REPORT_FILE: {
      const { data } = action;
      return {
        ...state,
        isLoading: false,
        isLoaded: !isEmpty(data),
        data: {
          ...data
        }
      };
    }
    case SUCCESS_LOAD_UPDATE_FILE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: {
          responseType: typeAlert.BP_SUCCESS
        }
      };
    }
    case ERROR_LOAD_REPORT_FILE: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }
    default:
      return state;
  }
};

export default reportFiles;
