const uk = {
  others: {
    generalStatistics: 'Загальна статистика',
    wpp: 'Вітряні електростанції (ВЕС)',
    spp: 'Сонячні електростанції (СЕС)',
    allWpp: 'Усі ВЕС',
    allSpp: 'Усі СЕС',
    WppSpp: 'ВЕС-СЕС',
    wpp_botievo: 'Ботієвська ВЕС',
    wpp_orlovsk: 'Орловська ВЕС',
    wpp_promorsk1: 'Приморська ВЕС-1',
    wpp_primorsk2: 'Приморська ВЕС-2',
    spp_nikopol: 'Нікопольська СЕС',
    spp_pokrovsk: 'Покровська СЕС',
    spp_trifanovsk: 'Тріфановська СЕС',
    windPowerPlants: 'Вітряні електростанції (ВЕС)',
    solarPowerPlants: 'Сонячні електростанції (СЕС) ',
    dashboard: 'Дашборд',
    powerBI: 'Power BI',
    unbalance: 'Небаланс',
    forecasts: 'Прогнози',
    map: 'Мапа',
    businessPlan: 'Бізнес план',
    users: 'Користувачі',
    restrictionsJournal: 'Журнал обмежень',
    restrictionsJournalCreate: 'Створення заявки',
    restrictionsJournalView: 'Перегляд заявки',
    restrictionsJournalEdit: 'Редагування заявки',
    forecastAndActualProduction: 'Прогнозна і фактична виробки (ВЕС, СЕС)',
    deviation: 'Відхилення',
    totalVariation: 'Сумарне відхилення',
    keyMetrics: 'Ключові метрики',
    liveData: 'Дані в реальному часі',
    budgetVsFact: 'Закладено в план і фактичні дані',
    generalUnbalanceTooltip: 'Позитивні і негативні небаланси, а також їх сума',
    unbalanceOutOfRangeTooltip: 'Позитивні і негативні небаланси, які виходять за допустимий поріг, а також їх сума',
    percentageOfPredictionsTooltip: 'Відсоток прогнозів які збулися в рамках допустимого порогу відхилень ',
    biasTooltip: 'Розбіжності між даними прогнозу і дійсними (фактичними) даними',
    nmaeTooltip: 'Нормалізована середня абсолютна помилка ',
    nrmseTooltip: 'Нормалізована середньоквадратична помилка',
    bPSuccess: 'Бізнес-план успішно завантажений',
    bpName: 'Назва БП',
    bpYear: 'Рік',
    bpAuthor: 'Автор',
    bpTemplate: 'Шаблон бізнес-плану',
    currentCapacity: 'Поточна потужність',
    projectCapacity: 'Проектна потужність',
    nestedListItems: 'Вкладені елементи списку',
    variationOfForecast: 'Відхилення прогнозного від фактичного виробітку',
    forecast: 'Прогнозний',
    actual: 'Фактично',
    station: 'Станція',
    namePlan: 'Бізнес план генерації ВЕС-СЕС',
    Production: 'Виробництво',
    EnergyAvailability: 'Доступність енергії',
    WindResource: 'Вітровий ресурс',
    Insolation: 'Інсоляція',
    forecastHorizon: 'Горизонт прогнозу',
    Average: 'В середньому',
    'Guaranteed Buyer': 'Гарантований покупець',
    Model: 'Модель',
    Fact: 'Фактичні',
    startDateRDC: 'Дата і час отримання команди від диспетчера РДЦ на введення обмеження',
    powerCommandRestriction: 'Потужність команди обмеження, +/-, МВт',
    powerBefore: 'Потужність станції до введення обмеження, МВт',
    endDateRDC: 'Дата і час отримання команди від диспетчера РДЦ на зняття обмеження',
    duration: 'Тривалість обмеження, хв',
    powerAfter: 'Потужність станції після виходу на необмеженої потужності при існуючих погодних умовах, МВт',
    lossesSCADA: 'Недовідпуск SCADA, МВтг',
    lossesCalculatedOrEtalon: 'Недовідпуск (розрахунковий або еталонний метод), МВт * год',
    positiveImbalance: 'Позитивний небаланс',
    negativeImbalance: 'Негативний небаланс',
    relativeUnbalanceIndex: 'Відносний показник небалансу',
    openPowerBI: 'Відкрити PowerBI у новій вкладці',
    OperationalJournal: 'Оперативний журнал',
    OperationalJournalCreate: 'Створити запис',
    OperationalJournalView: 'Переглянути запис',
    OperationalJournalEdit: 'Редагувати запис',
    OperationalJournalCreateRecord: 'Створення незалежного запису',
    Administration: 'Адміністрування',
    AdministrationCreate: 'Додати нового користувача',
    AdministrationEdit: 'Редагування даних користувача',
    AdministrationView: 'Перегляд даних користувача',
    status: 'Статус',
    includedInClosedChain: 'Входить у закритий ланцюжок',
    deletedStatus: 'Викреслена',
    event: 'Подія',
    causeCategory: 'Категорія причин',
    equipment: 'Обладняння',
    timestamp: 'Час',
    peopleId: 'ID користувача',
    email: 'Пошта',
    name: "Ім'я",
    surname: 'Прізвище',
    role: 'Роль',
    location: 'Локація',
    admin: 'Адміністратор',
    shiftSupervisor: 'Начальник зміни',
    powerEngineer: 'Інженер енергетик',
    expert: 'Експерт',
    dispatcher: 'Диспетчер',
    user: 'Користувач',
  },
  infoMessages: {
    noData: 'Немає даних',
    selectedPeriodNotMultipleCalendarWeek: 'Ви обрали період, який не кратний календарному тижню. Показники NMAE, NMRSE і bias будуть відображені некоректно',
    loadingPleaseWait: 'Зачекайте, триває завантаження',
    notAccess: 'Ця сторінка не відповідає правам доступу',
    connectSupport: 'Зверніться до служби підтримки для контролю доступу та відображення даних',
    dataLoadingError: 'Помилка завантаження даних',
    errorLoadingFile: 'Помилка завантаження файлу',
    errorLoadingDateEmptyData: 'Помилка завантажити дат, за які присутні дані',
    errorLoadingListForecasters: 'Помилка завантаження списку прогнозистів',
    updatingData: 'Оновлення даних',
    fileError: 'Помилка в файлі',
    uploadFileVerification: 'Завантажити файл для перевірки',
    overwriteData: 'Дані за період завантаження вже існують в базі. Ви впевнені що хочете перезаписати дані?',
    generalImbalance: 'Загальний небаланс',
    totalDeviation: 'Сумарне відхилення',
    title404: 'Сторінка, яку Ви шукаєте, не існує або була видалена',
    desc404_1: 'Спробуйте почати свій шлях з',
    desc404_2: 'головної сторінки',
    dataUpdateSuccess: 'Зміни успішно додані',
    deleteData: 'Заявка {{id}} успешно удалена',
    dataUpdateError: 'Зміни не додані Зверніться до служби підтримки для вирішення проблеми',
    dataCreateSuccess: 'Запис {{id}} успішно створено',
  },
  btn: {
    ok: 'Ok',
    save: 'Зберегти',
    cancel: 'Відмінити',
    downloadTemplate: 'Скачати шаблон',
    uploadBP: 'Завантажити БП ',
    downloadBP: 'Скачати БП',
    delete: 'Видалити',
    edit: 'Редагувати',
    not: 'Ні',
    yes: 'Так',
    crossOut: 'Викреслити',
  },
  unit: {
    mw: 'МВт',
    kWh: 'кВт*год',
    wm2: 'Вт/м²',
    mc: 'м/с',
    mWH: 'мВт•ч',
    kilowatt: 'КВт',
    kilowattMeter2: 'кВт/м²',
    percentageFell: 'Відсоток прогнозів який потрапив у межі нормальної роботи',
    hour: 'година',
    hours: 'години',
  },
  date: {
    today: 'Сьогодні',
    lastWeek: 'Минулий тиждень',
    currentWeek: 'Поточний тиждень',
    prevWeek: 'Попередній тиждень',
    currentMonth: 'Поточний місяць',
    PrevMonth: 'Попередній місяць',
    lastYear: 'Минулий рік',
    currentYear: 'Поточний рік',
    from: 'З',
    to: 'По',
    titleDRP: 'Виберіть період',
    hour: 'година',
    hours: 'години',
    calendar: 'Календар',
    year: 'Рік',
    weekToDate: 'З початку тижня',
    monthToDate: 'З початку місяця',
    yearToDate: 'З початку року ',
  }
};

export default uk;
