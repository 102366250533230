export const ENUM_HEAD = {
  ID_KEY: 1,
  ID_VALUE: 'ID',
  TIMESTAMP_KEY: 2,
  TIMESTAMP_VALUE: 'timestamp',
  EQUIPMENT_KEY: 3,
  EQUIPMENT_VALUE: 'equipment',
  CAUSE_CATEGORY_KEY: 4,
  CAUSE_CATEGORY_VALUE: 'causeCategory',
  EVENT_KEY: 5,
  EVENT_VALUE: 'event',
};

export const headCells = [
  {
    key: ENUM_HEAD.ID_KEY,
    value: ENUM_HEAD.ID_VALUE
  },
  {
    key: ENUM_HEAD.TIMESTAMP_KEY,
    value: ENUM_HEAD.TIMESTAMP_VALUE
  },
  {
    key: ENUM_HEAD.EQUIPMENT_KEY,
    value: ENUM_HEAD.EQUIPMENT_VALUE
  },
  {
    key: ENUM_HEAD.CAUSE_CATEGORY_KEY,
    value: ENUM_HEAD.CAUSE_CATEGORY_VALUE
  },
  {
    key: ENUM_HEAD.EVENT_KEY,
    value: ENUM_HEAD.EVENT_VALUE
  },
];

export const statusOLogList = [
  {
    key: 2,
    value: 'includedInClosedChain'
  },
  {
    key: 1,
    value: 'deletedStatus'
  },
];

export const MULTI_SELECT_ITEM_SIZE = 52;

export const mappingRequestStatus = {
  ALL: 1,
  DELETE: 2,
  NOT_DELETE: 3,
};

export const TEXT_LIMIT = 250;

export const OPTIONS_KEY = 'id';
export const OPTIONS_VALUE = 'name';
export const MAX_TIME = 15;
